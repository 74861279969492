//Styles for the 'Our Process' block on the Specialism template

.our-process {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: $color-body;
    color: #fff;

    display: grid;
    column-gap: 6rem;
    row-gap: 3rem;

    @include breakpoint(S-up) {
        grid-template-columns: 1fr 1fr;
        row-gap: 6rem;
    }

    &__title {
        color: $color-accent-primary;
        font-size: 5rem;
        margin-bottom: 0;

        @include breakpoint(S) {
            font-size: 3rem;
        }
    }

    &__step {
        display: grid;
        gap: 6rem;
        align-items: center;

        @include breakpoint(S-up) {
            grid-column: span 2;
            grid-template-columns: 1fr 1fr;

            &:nth-child(even) {
                .our-process__step-content-graphic {
                    order: 2;

                    .image-front {
                        left: auto !important;
                        right: 0;
                    }
                }
            }
        }
    }

    &__step-content-graphic {
        
        .img-wrap {
            position: relative;

            @include breakpoint(XS) {
                transform: scale(0.875);
            }
        }

        .image-front {
            position: absolute;
            top: 0;
            left: 0;

            @include breakpoint(XS) {
                width: 75%;
            }
        }

    }

    &__step-content {

    }
}