.content-container {
  max-width: 50em;
  margin: 0 auto;
  padding: 0 2em;

  ul ul {
    padding-left: 1.5em;
    padding-top: 1em;
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin-bottom: 1.5rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  max-width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 1.5em;

  figcaption {
    font-size: 0.875rem;
    opacity: 0.5;
    margin: 1.5rem 0 3rem 0;
    text-align: center;
  }
}

blockquote {
  font-family: $font-stack-secondary;
  font-size: 3em;
  margin-bottom: 1.5em;

  p {
    background-image: linear-gradient(
        #7acdc8,
        #7acdc8 0.4em,
        transparent 0.4em,
        transparent
      ),
      linear-gradient(
        to top,
        white,
        white 0.38em,
        transparent 0.38em,
        transparent
      ),
      linear-gradient(
        to right,
        rgb(122, 205, 200),
        rgb(122, 205, 200) 0%,
        transparent 0%,
        transparent
      );
    background-position: 0px 28px;
    display: inline;
  }
}

.wiki-list {
  margin-bottom: 2em;

  h2 {
    margin-bottom: 0.25em;
  }
}

#loginform {
  input[type="submit"] {
    color: #fff;
    border-color: #fff;
    &:hover {
      border-color: #fff;
      background: #fff;
      color: #7acdc8;
    }
  }
}

.contact-wrapper {
  min-height: 100vh;
  background: $color-accent-primary;
  display: flex;

  @include breakpoint(S) {
    min-height: 0;
  }
  .contact-content {
    width: 33.333%;

    .contact-top {
      border-bottom: 2px solid $color-body;
      padding: 3em 2em;

      h1 {
        font-family: $font-stack-secondary;
        margin: 0;
        font-size: 2.25em;
        padding-top: 1rem;
      }
    }
    .contact-bottom {
      padding: 3em 2em;

      p {
        &:first-child {
          font-size: 1.3em;
        }
        margin-bottom: 1em;

        a {
          color: #fff;

          &:hover {
            color: $color-body;
          }
        }
      }
    }
  }
  .contact-map {
    position: relative;
    width: 66.666%;
  }
  @include breakpoint(S) {
    display: block;

    .contact-content,
    .contact-map {
      width: 100%;
    }
    .contact-map {
      height: 50em;
    }
  }
}

.blog-listing{
  margin-block: 6rem;

  h2 {
    word-spacing: 0;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3rem;
    width: 50%;
  }
  
  .blog-listing-title {
    display: flex;
    margin-bottom: 2rem;
    gap: 3rem;
    align-items: center;
  }
  .content-lockup-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

    @include breakpoint(M){
      grid-template-columns:1fr 1fr;
    }

    @include breakpoint(S){
      grid-template-columns: 1fr;
    }

    .content-lockup {
      img {
        aspect-ratio: 379 / 280;
        margin-bottom: 2rem;
      }
    }
  }
}

.google-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.checkbox-hide-target {
  display: none;
}

.contact-map.in-page {
  width: 100%;
  height: 32em;
  position: relative;
}

.latest-news {
  background: #eaeef8;
  text-align: center;
  padding: 7em;
  border-top: 2px solid $color-body;
  border-bottom: 2px solid $color-body;

  @include breakpoint(S) {
    padding: 7em 2em;
  }
  h3 {
    color: $color-accent-primary;
    font-family: $font-stack-secondary;
    font-size: 3em;
    margin-bottom: 0.125em;

    @include breakpoint(S) {
      font-size: 2.5em;
    }
  }
  .news-title {
    font-size: 3em;
    display: block;
    border-bottom: 2px solid $color-body;
    color: $color-body;
    max-width: 15em;
    margin: 0 auto 0.5em auto;
    line-height: 1.2;

    @include breakpoint(S) {
      font-size: 2.5em;
    }
  }
}

.projects-grid {
  display: grid;
  gap: 2rem;
  padding-block: 3rem;

  .more-projects {
    justify-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    color: $color-body;

    @include breakpoint(S-up) {
      justify-self: end;
    }

    svg {
      vertical-align: middle;
      height: 1rem;
    }
    &:hover {
      color: $color-tertiary;
    }
  }
  .projects-list {
    display: grid;
    gap: 1.5rem;

    @include breakpoint(S-up) {
      &:has(> :nth-child(2)) {
        grid-template-columns: 1fr 1fr;
      }
    }

    @include breakpoint(M-up) {
      &:has(> :nth-child(3)) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      gap: 2rem;
    }

    .project-showcase {
      width: 100%;
      aspect-ratio: 16 / 10;
      height: auto;
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 71.87%, rgba(0, 0, 0, 0.40) 100%);
      }

      @include breakpoint(S-up) {

        &:has(> :nth-child(2)) {
          &:first-child {
            grid-column: span 2;
            grid-row: span 2;
            height: 100%;

            .project-details {
              padding: 2rem;
            }
  
            h2 {
              font-size: 3rem;
            }
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %transition;
      }
      .project-details {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
      }
      h2 {
        position: relative;
        color: #fff;
        display: inline;
        vertical-align: middle;
        z-index: 2;
        margin: 0;
        font-size: 1.75rem;

        svg {
          //Hide before hover
          opacity: 0;

          width: auto;
          height: 0.75em;
        }
      }
      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        opacity: 0;
        z-index: -1;
        @extend %transition;
      }

      .project-info {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s ease-out;
      }
      .project-info__content {
        //Hide before hovering
        opacity: 0;
        overflow: hidden;

        position: relative;
        z-index: 2;
        width: 100%;
        color: #fff;

        display: flex;
        gap: 2rem;
        justify-content: space-between;
        align-items: end;
        flex-wrap: wrap;

        p {
          margin: 0;
        }
        .tagline {
          flex-basis: 32rem;
          flex-shrink: 1;
          font-weight: 400;
          font-size: 0.875rem;
        }
        .cats {
          flex-shrink: 0;
          font-size: 0.875rem;
          font-weight: 700;

          a {
            color: $color-body;
          }
        }
      }
      &:hover {
        .hover {
          opacity: 0.9;
          z-index: 1;
        }
        img {
          transform: scale(1.05);
        }
        .project-info {
          grid-template-rows: 1fr;
        }
        .project-info__content {
          opacity: 1;
        }
        .cats {
          opacity: 1;
        }
        h2 {
          svg {
            opacity: 1;
            left: 105%;
          }
        }
      }
    }
  }
}

.logos-grid {
  border-top: 2px solid #908f8f;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(XS) {
    border: 0;
  }
  .single-logo {
    border-left: 2px solid #908f8f;
    border-bottom: 2px solid #908f8f;
    width: 25%;
    text-align: center;
    height: 15em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @extend %transition;
    }
    &:first-child,
    &:nth-child(5n) {
      border-left: 0;
    }
    @include breakpoint(S) {
      width: 50%;

      &:nth-child(odd) {
        border-left: 0;
      }
    }
    @include breakpoint(XS) {
      width: 12rem;
      border: 0;
      height: 12em;
      margin-left: 1rem;
      margin-right: 1rem;

      img {
        width: 100%;
        transform: scale(0.9);
        max-height: 80px;
        width: auto;
      }
    }
    &.slick-current {
      img {
        transform: scale(1);
      }
    }
  }
  &.dark-border {
    border-top: 2px solid $color-body;

    .single-logo {
      border-left: 2px solid $color-body;
      border-bottom: 2px solid $color-body;

      &:first-child,
      &:nth-child(5n) {
        border-left: 0;
      }
      @include breakpoint(S) {
        &:nth-child(odd) {
          border-left: 0;
        }
      }
      @include breakpoint(XS) {
        border: 0;
      }
    }
  }
  &.no-top-bottom-border {
    border-top: 0;

    .single-logo {
      border-bottom: 0;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: 2px solid $color-body;

        @include breakpoint(XS) {
          border: 0;
        }
      }
    }
  }
}

.feat-project {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5.5rem;

  @include breakpoint(XS) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
  .feat-proj-title{
    margin: auto;
    svg{
      width: 1100px;
      @include breakpoint(M) {
        width: 700px;
        height: auto;
      }

      @include breakpoint(XS) {
        width: 330px;
        height: auto;
      }
    }
  }
  .feat-proj-img {
    width: 60%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: calc(100% + 4em);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
      font-family: "object-fit: contain;";
    }
    @include breakpoint(M) {
      width: 55%;
    }
    @include breakpoint(XS) {
      width: 100%;
      padding: 0;
      order: 2;

      img {
        width: 25em;
        height: 18em;
        max-width: 100%;
        position: static;
        margin: 0 auto;
        transform: none;
        transform: translateY(3rem);
      }
    }
  }
  .feat-proj-content {
    width: 40%;
    padding: 4em 2em;
    position: relative;
    z-index: 1;

    .cats {
      color: #fff;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
    @include breakpoint(M) {
      width: 45%;
    }
    @include breakpoint(XS) {
      width: 100%;
      padding: 2em 0 0 0;
      text-align: center;
      order: 1;

      .button {
        display: inline-block;
      }
    }
  }
  h2 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 3em;

    @include breakpoint(XS) {
      font-size: 1.75rem;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      z-index: -1;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      filter: grayscale(1);
    }
  }
}

.gallery-images {
  display: none;
}

.projects-listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 1rem;


  @include breakpoint(S){
    grid-template-columns: 1fr;
  }

  .project {
    color: $color-body;

    h2 {
      margin-bottom: 0.25em;

      svg {
        @extend %transition;
      }
      @include breakpoint(S) {
        font-size: 1.5em;
      }
    }
    img {
      margin-bottom: 1em;
      width: 100%;
      height: 15em;

      @include breakpoint(XXL) {
        height: 20em;
      }
    }
    .cats {
      display: none;
    }
    &:hover {
      h2 svg {
        margin-left: 0.25em;
      }
    }
    &.full-width {
      width: calc(100% - 1em);

      img {
        width: 66.666%;
        height: 25.5em;

        @include breakpoint(XXL) {
          height: 35em;
        }
      }
      .project-content {
        position: absolute;
        top: 2em;
        bottom: 2em;
        left: calc(66.666% - 2em);
      }
    }
    &.half {
      width: calc(50% - 1em);

      @include breakpoint(XS) {
        width: calc(100% - 1em);
      }
    }
    &.two-third {
      width: calc(66.666% - 1em);
      margin-bottom: 20em;

      img {
        height: 30em;

        @include breakpoint(XXL) {
          height: 40em;
        }
      }
      @include breakpoint(XS) {
        width: calc(100% - 1em);
      }
    }
    .project-content {
      min-height: 10em;

      p {
        margin: 0;
        font-weight: 400;
      }
      @include breakpoint(S) {
        min-height: 5em;
      }
    }
    &.featured {
      .project-content {
        border: 2px solid $color-body;
        padding: 1em;
        color: #fff;

        svg g {
          fill: #fff;
        }
      }
      .cats {
        display: block;
        margin-top: 1em;
      }
    }
    &.double-height {
      .project-content {
        min-height: 20em;
        position: absolute;
        left: 2em;
        right: 2em;
        top: calc(100% - 3em);

        @include breakpoint(S) {
          min-height: 10em;
        }
      }
    }
    @include breakpoint(XS) {
      width: calc(100% - 1em);
    }
  }
}

.specialisms {
  background: $color-accent-primary;
  color: $color-body;
  padding-top: 5em;

  h1 {
    text-align: center;
    margin: 0 auto 0.5em auto;
    text-align: center;
    font-weight: 700;
  }
  @include breakpoint(XS) {
    .container.content-img{
      padding-bottom: 0;
      .content{
        padding-bottom: 0;
      }
    }
  }

  .specialisms-banner-content {
    max-width: 49rem;
    margin: 0 auto;
    @include breakpoint(XS) {
      padding: 0 2rem;
    }
    
    p {
      font-size: 1.75rem;
      color: $color-white;
    }
  }

  .content-img {
    background-color: $color-white;
    padding-top: 6rem;
    padding-bottom: 6rem;
    margin-bottom: 0;
    @include breakpoint(XS) {
      margin-top: 0;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .content {
      color: $color-body;
      padding-top: 5rem;
      @include breakpoint(XS) {
        padding-top: 3rem;
      }
    }

    .img {
      img {
        height: 100%;
      }
    }
  }

  .grid-list-main {
    background-color: $color-white;
    padding-bottom: 6rem;

    .content-section {
      h2 {
        font-weight: 700;
      }
    }

    .grid-list {
      .grid-list-item {
        border-right: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
        
        h4 {
          text-transform: none;
          letter-spacing: normal;
        }
      }
    }
  }

  .specialisms-wrapper {
    transition: all .4s ease-in-out;
    background-color: #3594c9;

    .specialism {
      padding: 5em 0;

      &:focus {
        outline: 0;
      }
      @include breakpoint(S) {
        padding: 5em 1em;
        clear: both;
      }
      @include breakpoint(XS) {
        padding: 2em 1em;
      }
      .container-capped {
        display: flex;

        .button {
          max-width: 16rem;
        }

        @include breakpoint(S) {
          display: block;
          .button.minor.icon-right{
            width: fit-content;
            display: flex;
          }
        }
      }
      &:nth-child(even) {
        // background: rgba(#a1dfdc, 0.4);

        .specialism-content {
          order: 2;
          padding-left: 6rem;

          @include breakpoint(S) {
            padding-left: 0;
          }
        }
        .specialism-list-items {
          order: 1;
        }
      }
      .specialism-content {
        display: flex;
        flex-wrap: wrap;
        width: 60%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include breakpoint(S) {
          width: 100%;
          margin-bottom: 5em;
        }
        @include breakpoint(XS) {
          display: block;
        }
        h2 {
          margin-bottom: 0.5em;
          width: 100%;
          font-weight: 700;
        }
        .button {
          font-size: 1.25em;
        }
        li {
          color: #fff;

          a {
            color: inherit;
            display: inline-block;
            position: relative;

            &:after {
              position: absolute;
              content: url('data:image/svg+xml;utf8,<svg width="20" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.363 16.8563C19.037 16.5233 19.037 16.1913 19.363 15.8583L24.806 9.70626H1.65C1.217 9.70626 1 9.48426 1 9.04126C1 8.59826 1.217 8.37626 1.65 8.37626H24.806L19.363 2.22626C19.037 1.89226 19.037 1.56026 19.363 1.22626C19.687 0.89626 20.013 0.89626 20.337 1.22626C24.617 6.04926 26.783 8.48626 26.837 8.54226C26.947 8.65226 27 8.81926 27 9.04226C27 9.26226 26.946 9.42926 26.837 9.54026L20.337 16.8553C20.229 16.9653 20.067 17.0213 19.85 17.0213C19.633 17.0213 19.47 16.9663 19.363 16.8553V16.8563Z" fill="white" stroke="white"/> </svg> ');
              display: inline-flex;
              align-items: center;
              left: 100%;
              width: 1rem;
              margin-left: 0.5rem;
            }

            &:hover {
              //Filter to change colour to approximate body colour
              filter: brightness(0) saturate(100%) invert(9%) sepia(64%)
                saturate(3%) hue-rotate(314deg) brightness(104%) contrast(104%);
              transform: translateX(0.25rem);
            }
          }

          &:before {
            background-image: url(/wp-content/themes/ADAO/images/arrow-dark.svg);
          }
        }
        .content-left {
          width: 75%;

          @include breakpoint(XS) {
            width: 100%;
            margin-bottom: 2em;
          }
        }
        .content-right {
          width: 50%;

          @include breakpoint(XS) {
            width: 100%;
          }
          ul {
            padding-left: 3.5em;

            @include breakpoint(XS) {
              padding-left: 0;
            }
            ul {
              padding-top: 1em;
              margin-bottom: 1em;
            }
          }
        }
      }

      .specialism-list-items {
        width: 40%;

        @include breakpoint(S) {
          width: 100%;
        }

        .specialism-list-item {
          display: flex;
          flex-direction: column;

          p {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

.specialism-image {
  margin-bottom: 1rem;
  .img-wrap {
    margin: 0 auto;
    display: inline-block;
    position: relative;

    .image-front {
      max-width: 11.75rem;

      @include breakpoint(M) {
        width: 80%;
      }
      @include breakpoint(S) {
        width: auto;
      }
    }
  }
}

.specialism-list {
  background-color: $color-body;
  padding: 1.5rem 0;
  margin: 0;
  text-align: center;

  li {
    padding: 0;
    margin: 0;
    display: inline;

    @include breakpoint(S) {
      width: 49%;
      display: inline-block;
    }
    &:before {
      display: none;
    }
    a {
      text-transform: uppercase;
      color: #fff;
      padding: 0 1em;
      font-weight: bold;
      font-size: 0.875rem;

      @include breakpoint(S) {
        padding: 0.5em 1em;
        display: block;
        font-size: 12px;
      }
      &:hover {
        color: $color-accent-primary;
      }
    }
  }
}

.callout {
  background: var(--color-background);
  
  text-align: center;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 3rem;
    margin-bottom: 0;

    img {
      display: inline-block;
      width: 1rem;
    }
  }
  p {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
  }

  + .block-links {
    margin-top: 5rem;
  }
}

.careers-landing {
  background: $color-body;
  color: #fff;
  padding: 1em;
}

.jobs-list {
  border: 1px solid #fff;
  max-width: 40em;
  margin: 0 auto;

  a {
    color: #fff;
    border: 1px solid #fff;
    display: block;
    padding: 1.5em 2em;

    h2 {
      margin: 0;
      padding-right: 1em;

      img {
        display: inline;
        @extend %transition;
      }
    }
    p {
      text-transform: uppercase;
      margin: 0;
      color: $color-accent-primary;
      font-weight: bold;
      letter-spacing: 0.125em;
    }
    &:last-child {
      border: 0;
    }
    &:hover {
      background: $color-accent-primary;

      img {
        transform: translateX(0.5em);
      }
      p {
        color: $color-body;
      }
    }
  }
}

.careers-top {
  padding-top: 3em;
  padding-bottom: 6em;
  display: flex;

  @include breakpoint(S) {
    display: block;
  }
  .career-top-icon {
    width: 33.333%;

    @include breakpoint(S) {
      width: 100%;
      margin-bottom: 4em;
    }
    img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
  }
  .career-top-content {
    border-bottom: 1px solid $color-accent-primary;
    column-count: 2;
    column-gap: 4em;
    width: 66.666%;

    @include breakpoint(S) {
      width: 100%;
      column-count: 1;
    }
    p {
      font-size: 1.125em;
      margin: 0;

      @include breakpoint(S) {
        margin-bottom: 1.5em;
      }
    }
  }
}

.careers-bottom {
  max-width: 40em;
  margin: 0 auto;
  padding: 5em 1em 12em 1em;

  h3,
  p {
    margin-bottom: 1em;
  }
  p:last-child {
    margin: 0;
  }
}

.wide-map {
  max-width: 72em;
  height: 32em;
  position: relative;
  margin: -7em auto 5em auto;

  @include breakpoint(S) {
    height: 20em;
  }
}

.what-we-do {
  padding: 5em 0 2em 0;

  h2 {
    font-size: 1.5em;
  }
  h3,
  a {
    color: $color-accent-primary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.5em;
  }
  p {
    margin: 0;
  }
  .container-capped {
    display: flex;

    @include breakpoint(XS) {
      display: block;
    }
    .what-do-title {
      width: 30%;

      @include breakpoint(XS) {
        width: 100%;
      }
    }
    .what-do-list {
      width: 70%;
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(XS) {
        width: 100%;
      }
      .what-do {
        width: 50%;
        padding-left: 2em;
        margin-bottom: 3em;

        @include breakpoint(XS) {
          width: 100%;
          padding-left: 0;
          margin-bottom: 2em;
        }
      }
    }
  }
}

.specialism-projects {
  .container-capped {
    display: flex;
    margin-bottom: 6rem;
    flex-direction: column;

    @include breakpoint(S) {
      display: block;
      margin-bottom: 4.5rem;
    }
  }
  .proj-title {
    width: 30%;

    h3 {
      text-transform: none;
      font-weight: normal;
      font-size: 1.5em;
    }
    @include breakpoint(S) {
      width: 100%;
    }
  }
  .projects-listing {

    @include breakpoint(S) {
      width: 100%;
    }
    .project {
      .project-content {
        min-height: 8em;

      }
      h2 {
        font-size: 1.5em;
      }
    }
  }
}

.awards {
  border-top: 2px solid $color-tertiary;
  border-bottom: 2px solid $color-tertiary;
  display: flex;
  margin-bottom: 5em;
  position: relative;
  width: 100%;

  @include breakpoint(S) {
    display: block;
  }
  .tag {
    font-size: 4.5em;
    font-family: $font-stack-secondary;
    width: 50%;
    text-align: center;
    padding: 1em 0;

    &.first {
      color: $color-accent-primary;
    }
    &.last {
      color: #e76780;
    }
    @include breakpoint(S) {
      width: 100%;
      padding: 0;
    }
    @include breakpoint(XS) {
      font-size: 2.5em;
    }
  }
  .awards-float {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 50%;
    display: flex;
    @extend %transition;

    @include breakpoint(S) {
      position: static;
      width: 100%;
      border-top: 2px solid $color-tertiary;
    }
    @include breakpoint(XS) {
      height: 10em;
    }
  }
  .award-display {
    width: 15em;
    position: relative;
    border-left: 2px solid $color-tertiary;
    background: #fff;

    @include breakpoint(S) {
      width: 33.333%;

      &:first-child {
        border-left: 0;
      }
    }
    &.last {
      border-right: 2px solid $color-tertiary;

      @include breakpoint(S) {
        border-right: 0;
      }
    }
    svg {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 60%;
      height: 60%;
      @extend %transition;
    }
    .award-pass {
      svg {
        opacity: 0;
      }
    }
  }
  .award-detail {
    width: 0;
    overflow: hidden;
    background: $color-accent-secondary;
    color: #fff;
    @extend %transition;

    .award-detail-inner {
      padding: 1em;
      width:15em;
    }
    h4 {
      font-size: 1em;
      margin: 0;

      @include breakpoint(L) {
        font-size: 0.75em;
      }
    }
    @include breakpoint(L) {
      h5 {
        font-size: 0.75em;
      }
    }
    p {
      color: $color-body;
      margin: 0;

      @include breakpoint(L) {
        font-size: 0.75em;
      }
    }
    &.open {
      max-width: 15em;
      width: 100%;
    }
  }
  &.pass {
    .awards-float {
      right: 50%;
    }
    .award-display {
      .award-initial {
        svg {
          opacity: 0;
        }
      }
      .award-pass {
        svg {
          opacity: 1;
        }
      }
    }
  }
}

.ticker {
  width: 100%;
  overflow-x: hidden;
  margin: 10px 0;

  img {
    float: left;
    margin-left: 10px;
    width: 300px;
  }
  @include breakpoint(S) {
    overflow-x: scroll;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;

    .ticker-images {
      transform: none !important;
      overflow: scroll;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.team {
  position: relative;

  .container {
    max-width: 75em;
    margin: 0 auto 3em auto;
    padding-bottom: 2em;

    .grid-sizer {
      width: 33%;

      @include breakpoint(S) {
        width: 50%;
      }
      @include breakpoint(XS) {
        width: 100%;
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40em;
    background: $color-accent-primary;
    z-index: -1;
  }
  .grid-element {
    width: 33%;
    margin-bottom: 1.5em;

    @include breakpoint(S) {
      width: 50%;
    }
    @include breakpoint(XS) {
      width: 100%;
      position: relative !important;
      top: auto !important;
      left: auto !important;
    }
    &:nth-child(3) {
      margin-top: 3em;

      @include breakpoint(XS) {
        margin-top: 0;
      }
    }
    &:nth-child(4) {
      margin-top: 6em;

      @include breakpoint(S) {
        margin-top: 0;
      }
    }
  }
  .team-member {
    border: 2px solid $color-body;
    text-align: center;
    @extend %transition;
    background: #fff;
    margin: 0 0.75em;
    display: block;

    h3 {
      margin: 0 0 0.75em 0;
      font-size: 1.125em;
    }
    .member-img {
      position: relative;
      width: 100%;
      height: 24em;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %transition;

        &:first-child {
          z-index: 1;
        }
        &:last-child {
          z-index: 2;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    p {
      margin: 0;
      text-transform: uppercase;
      line-height: 1;
    }
    .member-details {
      padding: 1.5em 1em;
      border-top: 2px solid $color-body;
      @extend %transition;
    }
    &:hover {
      border: 2px solid $color-accent-secondary;
      cursor: help;

      .member-details {
        border-top: 2px solid $color-accent-secondary;
      }
      .member-img {
        img {
          &:last-child {
            opacity: 1;
            visibility: visible;
            transform: rotate(5deg) scale(1.1);
          }
        }
      }
    }
  }
}

.container a.lookout {
  display: block;
  color: $color-body;
  padding: 2em 1em 1em 1em;
  max-width: 28rem;

  p {
    font-size: 2.25em;
    margin: 0;
    margin-bottom: 0.25em;
  }
  .lookout-icon {
    display: block;
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .arrow-icon {
    width: 3em;
    height: 2.5em;
    @extend %transition;
  }
  &:hover {
    color: #fff;

    .arrow-icon {
      transform: translateX(1em);
    }
  }
}

.error-404-content {
  display: flex;
  padding-top: 5em;
  padding-bottom: 5em;

  h1 {
    font-family: $font-stack-secondary;
    font-size: 5.75em;
    margin-bottom: 0.25em;

    @include breakpoint(S) {
      font-size: 3em;
    }
  }
  h2,
  p {
    color: #9b9b9b;
  }
  h2 {
    margin-bottom: 0.75em;

    @include breakpoint(S) {
      font-size: 1.5em;
    }
  }
  .error-404-left {
    padding-right: 3em;

    @include breakpoint(S) {
      padding-right: 1em;
    }
  }
  p {
    font-size: 1.5em;
    margin-bottom: 1em;

    @include breakpoint(S) {
      font-size: 1em;
    }
  }
  .menu-404 {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;

      &:before {
        display: none;
      }
      a {
        text-decoration: none;
        color: $color-accent-primary;
        font-size: 1.5em;

        @include breakpoint(S) {
          font-size: 1em;
        }
        &:hover {
          color: #fff;
        }
        &:before {
          display: none;
        }
      }
    }
  }
  .error-404-right {
    img {
      max-width: none;

      @include breakpoint(S) {
        width: 100px;
        height: auto;
      }
    }
  }
}
.projects-container {
  padding: 8em 0;
  text-align: center;
  position: relative;
  @extend %transition;
  background: #7acdc8;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 10%;
    bottom: 0;
    z-index: 5;

    @include breakpoint(S) {
      width: 5em;
    }
    @include breakpoint(XS) {
      display: none;
    }
  }
  &:before {
    left: 0;
    background: -moz-linear-gradient(
      left,
      rgba(24, 24, 24, 0.5) 0%,
      rgba(24, 24, 24, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(24, 24, 24, 0.5) 0%,
      rgba(24, 24, 24, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(24, 24, 24, 0.5) 0%,
      rgba(24, 24, 24, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181818', endColorstr='#00181818',GradientType=1 ); /* IE6-9 */
  }
  &:after {
    right: 0;
    background: -moz-linear-gradient(
      left,
      rgba(24, 24, 24, 0) 0%,
      rgba(24, 24, 24, 0.5) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(24, 24, 24, 0) 0%,
      rgba(24, 24, 24, 0.5) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(24, 24, 24, 0) 0%,
      rgba(24, 24, 24, 0.5) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00181818', endColorstr='#181818',GradientType=1 ); /* IE6-9 */
  }
  h1 {
    color: #fff;
    font-size: 4em;
    margin-bottom: 0.5em;
  }
  .projects-slider a {
    width: 30em;
    margin: 0.5em;

    @include breakpoint(XS) {
      width: 20em;
    }
    img {
      height: 20em;
      margin-bottom: 2.5em;

      @include breakpoint(XS) {
        height: 12em;
      }
    }
    h2 {
      color: #fff;
      margin: 0;
      font-size: 1.5em;
      position: relative;
      display: inline-block;
      @extend %transition;
      opacity: 0;

      &:after {
        content: "";
        position: absolute;
        left: -0.25em;
        right: -0.25em;
        bottom: -0.125em;
        height: 2px;
        background: #fff;
        @extend %transition;
      }
    }
    &.slick-active {
      h2 {
        opacity: 1;
      }
    }
    &:hover {
      h2 {
        &:after {
          bottom: -0.25em;
        }
      }
    }
  }
}

.slick-slide:focus {
  outline: 0;
}

.projects-slider {
  position: relative;

  .slick-arrow {
    background: none;
    border: 0;
    bottom: 0;
    z-index: 6;

    &.slider-prev {
      right: auto;
      left: calc(50% - 15em);

      &:hover {
        left: calc(50% - 15.25em);
      }
      @include breakpoint(XS) {
        left: 0.5em;

        &:hover {
          left: 0.25em;
        }
      }
    }
    &.slider-next {
      right: calc(50% - 15em);

      &:hover {
        right: calc(50% - 15.25em);
      }
      @include breakpoint(XS) {
        right: 0.5em;

        &:hover {
          right: 0.25em;
        }
      }
    }
  }
}

.studio-content {
  margin-bottom: 5em;
  // display: flex;
  @media (max-width: 1200px) {
    padding-top: 4rem;
  }
  @include breakpoint(S) {
    padding-top: 0;
  }

  @include breakpoint(S) {
    display: block;
  }
  .studio-content-left {
    width: 60%;
    padding-right: 2em;

    @include breakpoint(S) {
      width: 100%;
      display: block;
      padding-right: 0;
    }
  }
  .studio-content-right {
    // width: 40%;
    width: 100%;
    overflow: visible;
    display: block;
    // display: flex;
    // align-items: flex-end;

    @include breakpoint(S) {
      padding-top: 0;
      width: 100%;
      display: block;
    }


  }
}

.js-instagram-feed {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoint(S) {
    margin-top: 2rem;
    position: relative;
    top: auto;
  }
  .instagram-feed-wrap{
    width: 45%;
  }
}

.instagram-feed {
  padding: 7rem 2rem 2rem 2rem;
  color: #fff;
  margin-right: 5rem;
  width: calc(100% - 5rem);
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e60d3+0,913baf+18,d52d88+82,f26d4f+100 */
  background: rgb(78, 96, 211); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(78, 96, 211, 1) 0%,
    rgba(145, 59, 175, 1) 18%,
    rgba(213, 45, 136, 1) 82%,
    rgba(242, 109, 79, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(78, 96, 211, 1) 0%,
    rgba(145, 59, 175, 1) 18%,
    rgba(213, 45, 136, 1) 82%,
    rgba(242, 109, 79, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(78, 96, 211, 1) 0%,
    rgba(145, 59, 175, 1) 18%,
    rgba(213, 45, 136, 1) 82%,
    rgba(242, 109, 79, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e60d3', endColorstr='#f26d4f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  @include breakpoint(XS) {
    margin-right: 2rem;
    width: calc(100% - 2rem);
  }
  .slick-list {
    overflow: visible;
  }
  .insta-caption {
    font-size: 0.875rem;
  }
  a {
    color: #fff !important;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
    font-size: 1.215em;
    letter-spacing: 2px;

    &:hover {
      color: $color-accent-primary !important;
    }
  }
  .instagram-logo {
    position: absolute;
    left: 2em;
    top: 2em;
    width: 3em;
    height: 3em;
  }
}

.insta-img {
  width: calc(100% - 7rem);
  margin-left: 7rem;
  transform: translateY(5rem);
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
  }
}

.studio-vid {
  position: relative;

  @include breakpoint(XS) {
    margin-bottom: 5em;
  }
  .video {
    margin-bottom: 5em;

    @include breakpoint(XS) {
      margin-bottom: 0;
    }
    .vid-modal-trigger {
      width: 75%;
      height: 32em;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include breakpoint(M) {
        width: 80%;
      }
      @include breakpoint(XS) {
        width: 100%;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#fff, 0.25);
        z-index: 1;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &:hover {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .icon {
      width: 10em;
      height: 10em;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      svg {
        width: 6em;
        height: 6em;
        @extend %transition;
      }
    }
  }
  .underlined {
    position: absolute;
    bottom: -0.75em;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    min-width: 9em;
    color: $color-body;
    font-size: 2.25em;
    border-bottom: 2px solid $color-body;
  }
  .vid-adao {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25em;

    @include breakpoint(XS) {
      width: auto;
      height: auto;
      z-index: 1;
      left: 1em;
      right: 1em;
      min-width: 0;
      bottom: -2em;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .lot {
      letter-spacing: 5px;
      font-size: 4em;
      position: relative;
      z-index: 1;
      transform: translateY(8px);

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        transform: none;
      }
      @supports (-ms-ime-align: auto) {
        /* IE Edge 12+ CSS styles go here */
        transform: none;
      }
    }
    .char {
      font-size: 1.5em;
      font-weight: bold;
      position: relative;
      z-index: 1;
    }
  }
}

.block-links {
  margin-left: -0.5em;
  margin-right: -0.5em;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @include breakpoint(S) {
    flex-direction: column;
  }
  a {
    background: $color-accent-primary;
    flex-grow: 1;
    margin: 0.5em;
    padding: 2em;
    flex-basis: 0;

    p {
      color: $color-body;
      @extend %transition;
    }
    &:hover {
      p {
        color: #fff;
      }
    }
  }
}

@include breakpoint(S-up) {
  .image-indent,
  .image-indent-left,
  .alignleft {
    float: left;
    margin-right: 2em;
  }
  .image-indent-right,
  .alignright {
    float: right;
    margin-left: 2em;
  }
}

.grecaptcha-badge {
  z-index: -10000 !important;
  visibility: hidden !important;
}

.counter-wrapper {
  background: $color-accent-primary;
  padding: 3em 2em;
  text-align: center;
  margin-bottom: 2em;

  p {
    margin: 0;
    font-size: 4em;
    font-weight: bold;
    color: #fff;
  }
}

.images-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 25%;
  }
}

//Full width CTA styling
.full-width-call-out {
  width: 100%;
  padding: 7.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  @include breakpoint(S) {
    padding: 3.25rem;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 1);
    mix-blend-mode: multiply;
    opacity: 0.75;
  }
  .content-inner-fwco {
    max-width: 47.5625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 3;
    color: white;
  }
  .image-fwco {
    overflow: hidden;
    z-index: 1;
    right: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

//Grid Icons styling for why us page
.grid-list-main {
  .content-section {
    display: flex;
    align-items: center;
    @include breakpoint(S) {
      flex-direction: column;
    }
    .c-title {
      width: 50%;
      @include breakpoint(S) {
        width: 100%;
      }
    }
    .content-subtitle {
      display: flex;
      width: 50%;
      padding-left: 5rem;
      padding-right: 5rem;
      @include breakpoint(S) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.top-min{
  padding-top: 3rem !important;
  @include breakpoint(S) {
    padding-top: 1.938rem !important;
  }
}
.bottom-min{
  padding-bottom: 3rem !important;
  @include breakpoint(S) {
    padding-bottom: 1.938rem !important;
  }
}

.grid-list {
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: hidden;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 64em) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .grid-list-item {
    width: 100%;
    padding: 3rem 1.5rem;
    text-align: center;
    margin-bottom: -1px;
    margin-top: 1px;
    margin-right: -1px;
    margin-left: 1px;
    border-right: 1px solid rgba(0, 8, 5, 0.1);
    border-bottom: 1px solid rgba(0, 8, 5, 0.1);
    background-color: #7acdc8;

    // aspect-ratio: 16 / 10;
    min-height: 16rem;
    display: flex;
    justify-content: start;

    &:is(a) {
      &::after {
        content: "";
        position: absolute;
        bottom: 1rem;
        width: 0.5rem;
        height: 0.5rem;
        background: $color-body;
        border-radius: 100%;
        @extend %transition;
      }

      &:hover {
        background-color: $color-body;

        &::after {
          background: $color-accent-primary;
        }

        h4 {
          color: $color-white;
          transition: 0.2s ease-in-out;
        }
      }
    }

    &:last-child {
      border-right: none;
    }
    .list-content,
    h5,
    s p {
      width: 100%;
    }
    h4 {
      color: $color-body;
    }
  }
  &.mobile-scroll {
    @include breakpoint(XS) {
      margin-left: -2rem;
      margin-right: -2rem;
      padding-left: 2rem;
      grid-auto-flow: column;
      overflow-x: scroll;
      scroll-snap-type: proximity;

      .grid-list-item {
        width: 16rem;
        height: 16rem;
        margin: 0;
      }
    }
  }
  @include breakpoint(XS) {
    overflow: visible;
  }
}

.list-counter {
  flex-shrink: 0;

  &.numbers {
    color: white;
    font-weight: 500;
    font-family: "lotregular", "Impact", "Haettenschweilier", "Arial Black",
      monospaced;
  }
}

//Image caption styling

.wp-caption-text {
  text-align: center;
  margin-top: 1.5rem;
  opacity: 0.5;
}

.content-img {
  background: $color-accent-primary;
  display: flex;
  padding-top: 9rem;
  margin-bottom: 10rem;

  @include breakpoint(XS) {
    flex-direction: column;
    padding-top: 0;
    margin-top: 5rem;
    margin-bottom: 2rem;

    .button {
      display: inline-block;
    }
  }
  .content {
    width: 50%;
    padding-right: 3rem;
    color: #000;
    padding-bottom: 5rem;

    h2 {
      font-weight: bold;

      @include breakpoint(S){
        margin-top: 1rem;
      }
    }
    a {
      // color: $color-body;
      color: #000;
      font-weight: 700;

      &:hover {
        color: $color-tertiary;
      }
    }
    @include breakpoint(XS) {
      width: 100%;
      order: 2;
      padding-right: 0;
      padding-bottom: 3rem;
    }
  }
  .img {
    width: 50%;
    position: relative;

    @include breakpoint(XS) {
      width: 100%;
      order: 1;
      height: 16rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 3rem;
      width: 100%;
      max-height: 100%;
      aspect-ratio: 1;
      height: auto;
      // height: calc(100% + 5rem);

      @include breakpoint(XS) {
        left: 0;
        top: 0rem;
        height: 100%;
      }
    }
  }
}

.quote-carousel-wrapper {
  text-align: center;
  margin-bottom: 2rem;

  &.hide-desktop {
    margin-bottom: 0;
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .quote-carousel {
    margin-bottom: 2.875rem;

    @include breakpoint(XS) {
      margin-bottom: 1.5rem;
    }
  }
  .quote {
    font-size: 2.25rem;
    margin-bottom: 1.75rem;

    @include breakpoint(XS) {
      font-size: 1.25rem;
    }
  }
  .author {
    font-weight: bold;
    color: $color-accent-primary;
    margin: 0;
    text-transform: uppercase;

    @include breakpoint(XS) {
      font-size: 0.875rem;
    }
  }
  .position {
    margin: 0;

    @include breakpoint(XS) {
      font-size: 0.875rem;
    }
  }
}

.quotes-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  a, button {
    display: block;
    margin: 0 0.5rem;
    border: none;
    &::after {
      content: none;
    }
  }
  svg {
    display: block;
  }
}

.quotes-dots {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  li {
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }
  }
  a {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    border: 2px solid $color-body;
    background: #fff;
  }
  .slick-active {
    a {
      background: $color-accent-primary;
    }
  }
}
.callout-tag {
  text-align: center;
  padding: 2rem;
  a {
    color: #7acdc8;
  }
}
.work-tags {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -4rem;
  margin-bottom: 1rem;
  @include breakpoint(S) {
    padding-left: 2rem;
    justify-content: center;
  }
  a.button {
    margin: 0 0.75rem;
    padding:0 0 0.5rem 0;
    display: flex;
    border:none;
    color: #181818;
    &:before{
      content: "";
      transition: all 0.5s cubic-bezier(0, 0, 0.21, 0.99);
      display: flex;
      background: #ffffff;
      position: absolute;
      bottom: -3.2px!important;
      width: 100%!important;
      height: 3.2px!important;
      z-index: -1;
    }
    &:after{
      content: "";
      display:none;
    }
    &:hover{
      display: flex;
      background: #ffffff;
      &:before{
        content: "";
        background: #7acdc8;
        bottom: 0!important;
      }
      &:after{
        content: "";
        display:none;
      }

    }
    &.feat {
      &:before{
        content: "";
        transition: all 0.5s cubic-bezier(0, 0, 0.21, 0.99);
        display: flex;
        background: #7acdc8;
        position: absolute;
        bottom: 0px!important;
        width: 100%!important;
        height: 3.2px!important;
        z-index: -1;
      }
    }
  }
}

//Image Content Carousel Block
.image-content-carousel-block-styles{
  width: 100%;
  margin: 0!important; //needed when within page-builder
  margin-top:2rem;
  margin-bottom: 2rem;
  .image-content-carousel-block{
    width:100%;
    height: auto;
    background-color: #000000;
    padding-top:6rem;
    padding-bottom: 6rem;
    padding-left:7.5rem;
    padding-right: 7.5rem;
    display: flex;
    @include breakpoint(S){
        flex-direction: column;
        padding-left:2rem;
        padding-right: 2rem;
    }
    .ic-carousel-controls{
      width:6.25rem;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap:1rem;
      @include breakpoint(S){
        flex-direction: row;
        margin: 0 auto;
        order: 2;
        padding-top: 2rem;
        gap:5rem;
      }
     
      .control-button-icon{
        width: 2.8333333333333335rem;
        height: 2.8333333333333335rem;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 1e+06px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s ease-out;
        svg{
          width: inherit;
        }

        &:hover{
          border-color: $color-accent-primary;
          transform: scale(1.1);
          transition: all 0.5s ease-out;
          svg{
            color: $color-accent-primary;
            path{
              stroke: $color-accent-primary;
            }
          }
        }
      }
    }
    .js-image-content-carousel{
       width:calc(100% - 6rem);
       @include breakpoint(S){
         width: 100%;
       }
    }
    .image-content-slide{
      display: flex;
      width: 100%; //fallback
      width: fit-content;
      @include breakpoint(S){
        flex-direction: column;
      }
      .ics-image{
        width: 50%;
        position: relative;
        margin-right: 5.125rem;
        min-height: 31.5rem;
        @include breakpoint(S){
          min-height: 25.5rem;
          width: 100%;
          margin-right: 0;
          aspect-ratio: 1;
        }
        img{
          width: 100%;
          height: auto;
          aspect-ratio: 1;
          position: absolute;
          object-fit: cover;
        }
      }
      .ics-content{
        width:50%;
        padding-top:2rem;
        padding-bottom: 2rem;
        color:white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include breakpoint(S){
          width: 100%;
          padding-bottom: 0;
        }
        h2{
          display: flex;
          flex-direction: column;
          font-weight: 700;
          span{
            background-color: transparent;
            margin:0;
            font-weight: 700;
            margin-top: 0.5rem;
          }
        }
        p{
          a{
            // color:$color-accent-primary;
            color: $color-white;
            font-weight: 700;
          }
        }
        .button{
          width: fit-content;
        }
      }
    }
  }
}

.home-banner{
  width: 100%;
  min-height: 45rem;
  display: flex;
  @include breakpoint(M){
    flex-direction: column;
  }
  .banner-logo{
    position: absolute;
    top:1.5rem;
    left: 1.25rem;
    width: 6.7rem;
    svg{
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .banner-content{
      width: 50%;
      padding-left: 6rem;
      padding-right: 6rem;
      padding-bottom: 6rem;
      padding-top: 17.5rem;
      background-color: $color-body;
      color: white;
      @include breakpoint(M){
        width: 100%;
      }
      @include breakpoint(S){
        padding-left: 3rem;
        padding-right: 3rem;
      }
      h1{
        font-weight: bold;
        line-height: initial;
        margin-bottom: 6rem;
        svg{
          width: 2.4375rem;
          height: 2.4375rem;
        }
        span{
          background-color: transparent;
          padding: unset;
          font-size: inherit;
          transform: unset;
          position: relative;
          z-index: 1;
          margin-left: unset;
          display: inline;
          &::after{
            content: "";
            width: 100%;
            height:0.75rem;
            background-color: $color-accent-primary;
            position: absolute;
            bottom: 0.2rem;
            left: 0;
            right: 0;
            z-index: -1;
            
          }
        }
      }
  }
  .banner-image{
      width: 50%;
      position: relative;
      background-size: cover;
      background-position: center;
      background-blend-mode: multiply;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(1);
      }
      &::after{
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        background: $color-accent-primary;
        mix-blend-mode: multiply;
      }
      @include breakpoint(M){
        width: 100%;
        justify-content: center;
      }
      .svg-container{
        margin:9.25rem;
        position: relative;
        z-index: 9;
        @include breakpoint(M){
          margin:3.25rem;
        }
        svg{
          width: 100%;
          height: 100%;
        }
      }
      
  }
}

.general-new-size{
  width: 100vw;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  @include breakpoint(XL) {
    margin-left: calc(-5em - 2.5vw + 0.75em);
    margin-right: calc(-5em - 2.5vw + 0.75em);
  }
  @include breakpoint(S) {
    margin-left: -2.2rem;
    margin-right: 0;
  }
}
.standard-banner{
  background-color: #efb851;
  min-height: 45rem;
  display: flex;

  @include breakpoint(XL) {
    margin-left: calc(-5em - 2.5vw + 0.75em);
    margin-right: calc(-5em - 2.5vw + 0.75em);
  }
  @include breakpoint(M){
    flex-direction: column;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  @include breakpoint(S){
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4rem;
  }
  .banner-logo{
    position: absolute;
    top:1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 6.7rem;
    display: none;
    @include breakpoint(S){
      display: block;
    }
    svg{
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .banner-content{
      width: 50%;
      padding-left: 6rem;
      padding-right: 6rem;
      padding-bottom: 8.5rem;
      padding-top: 8.5rem;
      display: flex;
      justify-content: center;
      // background-color: $color-body;
      color: white;

      .banner_svg {
        width: 18rem;
      }

      @include breakpoint(M){
        width: 100%;
      }
      @include breakpoint(M){
        padding-left: 2.12rem;
        padding-right: 2.12rem;
        padding-bottom: 3rem;
        svg{
          width: 64px;
          height: auto;
        }
      }
      h1{
        font-weight: bold;
        line-height: initial;
        margin-bottom: 6rem;
        word-spacing: 0;
        svg{
          width: 2.4375rem;
          height: 2.4375rem;
          @include breakpoint(M){
            width: 64px;
            height: auto;
          }
        }
        span{
          background-color: transparent;
          padding: unset;
          font-size: inherit;
          transform: unset;
          position: relative;
          z-index: 1;
          margin-left: unset;
          display: inline;
          &::after{
            content: "";
            width: 100%;
            height:0.75rem;
            background-color: $color-accent-primary;
            position: absolute;
            bottom: 0.2rem;
            left: 0;
            right: 0;
            z-index: -1;
            
          }
        }
      }
  }
  .banner-image{
      width: 50%;
      position: relative;
      background-size: cover;
      background-position: center;
      background-blend-mode: multiply;
      .banner-description{
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-right: 6rem;
        h2, .h2 {
          font-weight: 700;
          font-size: 3rem;
          line-height: 4rem;
        
          @include breakpoint(S) {
            font-size: 2rem;
            line-height: 1.25;
          }
        }
        p{
          font-size: 1rem;
          line-height: 1.5;
        }
        @include breakpoint(S) {
          padding-right: 0;
        }
        .go-back{
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin-bottom: 2rem;
          z-index: 9;
          cursor: pointer;
          color: $color-body;
          svg{
            margin-right: 1.188rem;
          }
        }
        .button {
          max-width: 12rem;
        }
      }
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(1);
      }
      &::after{
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        // background: $color-accent-primary;
        // mix-blend-mode: multiply;
      }
      @include breakpoint(M){
        width: 100%;
        justify-content: center;
      }
      @include breakpoint(S){
        padding-left: 2.12rem;
        padding-right: 2.12rem;
      }
      .svg-container{
        margin:9.25rem;
        position: relative;
        z-index: 9;
        @include breakpoint(M){
          margin:3.25rem;
        }
        svg{
          width: 100%;
          height: 100%;
        }
      }
      
  }
}

.list-services-block{
  background-color: #191919;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include breakpoint(S) {
    padding-left: 2.12rem;
    padding-right: 2.12rem;
  }

  @include breakpoint(M){
    flex-direction: column;
  }
  .head-list{
    background-color: #EFB851;
    color: #191919;
    padding: 1rem;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: fit-content;
    margin-bottom: 3rem;
  }
  .services{
    display: flex;
    flex-wrap: wrap;
    .service{
      color: #fff;
      background-color: transparent;
      width: 50%;
      &:nth-of-type(odd) {
        padding-right: 2rem;
      }
      &:nth-of-type(even) {
        padding-left: 2rem;
      }
      h3{
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0;
      }
      .subtitle{
        color: #fff;
        background-color: transparent;
        padding: 0;
        margin: 0;
        transform: unset;
        margin-top: .5rem;
        p{
          text-transform: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0;
        }
      }
      @include breakpoint(S) {
        &:last-of-type{
          h3 .subtitle p{
            margin-bottom: 0;
          }
        }
      }
    }
    @include breakpoint(S){
      flex-direction: column;
      .service{
        width: 100%;
        padding: 0 !important;
      }
    }
  }
}
.content-two-columns{
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #EFB851;
  color: #191919;
  text-align: left;
  &.yellow {
    background: #EFB851;
  }
  &.red {
    background: #D76062;
  }
  &.orange {
    background: #F3843F;
  }
  &.blue {
    background: #3594C9;
  }
  @include breakpoint(S) {
    padding: 1.938rem 2.12rem;
  }
  h3{
    width: 50%;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0;
    text-transform: none;
    @include breakpoint(S) {
      width: 100%;
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;
    }
  }
  .content{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    column-count: 2;
    @include breakpoint(S) {
      column-count: 1;
      p{
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
  }
}
.counter-block{
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include breakpoint(S) {
    padding: 1.938rem 2.12rem;
  }
  .content{
    display: flex;
    flex-direction: column;
    .counter-wrapper{
      text-align: center;
      padding: 6rem;
      background-color: #EFB851;
      width: fit-content;
      margin: auto;
      margin-bottom: 0;
      @include breakpoint(S) {
        padding: 1.93rem;
      }
      h4{
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #191919;
        margin-bottom: 3.25rem;
        @include breakpoint(S) {
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          margin-bottom: 1.93rem;
        }
      }
      p{
        margin: 0;
        font-size: 0;
        span.counter{
          color: #191919;
          font-weight: 700;
          font-size: 72px;
          line-height: 64px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          @include breakpoint(S) {
            font-weight: 700;
            font-size: 28px;
            line-height: 35px;
            color: #fff;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
.content-button{
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #191919;
  color: #fff;
  text-align: center;
  @include breakpoint(S) {
    padding: 1.938rem 2.12rem;
  }
  .content-head{
    margin-bottom: 3rem;
    h3{
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      letter-spacing: 0;
      text-transform: none;
    }
    .subtitle{
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
    }
  }
  @include breakpoint(S) {
    .content-head{
      margin-bottom: 1.938rem;
      h3{
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        text-transform: none;
      }
      .subtitle{
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
}
.pins-clients-block{
  overflow: hidden;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include breakpoint(S) {
    padding-left: 2.12rem;
    padding-right: 2.12rem;
  }
  .head-list{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2.688rem;
    h3{
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 1rem;
      letter-spacing: 0;
      text-transform: none;
    }
    .description{
      p{
        margin-bottom: 0;
        font-weight: 400;
        font-size: 21px;
        line-height: 28px;
      }
    }
  }
  .pins{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: wrap-reverse;
    @include breakpoint(M) {
      padding-right: 11rem;
    }
    @include breakpoint(S) {
      padding-right: 0;
    }
    .pin{
      width: 25%;
      display: flex;
      justify-content: center;
      position: relative;
      svg{
        position: absolute;
        z-index: 2;
        width: 30%;
        height: auto;
        top: 0.5rem;
      }
      .fullpin{
        position: relative;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        margin: 3rem;
        top: -1.5rem;
        border: 5px solid black;
        &::after{
          content: "";
          position: absolute;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: #EFB851;
          mix-blend-mode: color;
          min-width: 160px;
        }
        img{
          width: 100%;
          border-radius: 50%;
          aspect-ratio: 1/1;
          min-width: 160px;
        }
        span{
          position: absolute;
          left: 50%;
          top: calc(50% + 2rem);
          transform: translate(-50%, -50%);
          font-family: $font-stack-primary;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(M) {
      width: 100vw;
      justify-content:unset;
      overflow: scroll;
      left: -2rem;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      .pin{
        width: 145px !important;
        flex-shrink: 0;
        margin: 0;
        padding: 0 .5rem;
        margin-bottom: 2rem;
        &:first-of-type{
          margin-left: 2rem;
        }
        &:last-of-type{
          margin-right: 2rem;
        }
        svg{
          position: absolute;
          z-index: 2;
          width: 70px;
          top: 0;
          height: fit-content;
        }
        .fullpin{
          position: relative;
          width: 100%;
          aspect-ratio: 1/1;
          border-radius: 50%;
          margin: 0;
          top: 1rem;
          border: 3px solid black;
          &::after{
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #EFB851;
            mix-blend-mode: color;
            min-width: unset;
          }
          img{
            width: 100%;
            border-radius: 50%;
            aspect-ratio: 1/1;
            min-width: unset;
          }
          span{
            position: absolute;
            left: 50%;
            top: calc(50% + 2rem);
            transform: translate(-50%, -50%);
            font-family: $font-stack-primary;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.list-steps-block{
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include breakpoint(S) {
    padding-left: 2.12rem;
    padding-right: 2.12rem;
  }

  @include breakpoint(M){
    flex-direction: column;
  }
  .head-list{
    display: flex;
    flex-direction: row;
    h2{
      width: 50%;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
    }
    .description{
      width: 50%;
      padding-left: 9rem;
      p{
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    @include breakpoint(M) {
      flex-direction: column;
      h2{
        width: 100%;
      }
      .description{
        width: 100%;
        padding: 0;
        margin-bottom: 2.188rem;
      }
    }
  }
  .steps{
    display: flex;
    flex-direction: row;
    .step{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3.625rem 1rem;
      background-color: #EFB851;
      border-right: 1px solid white;
      &:last-of-type{
        border-right: none;
      }
      h3{
        font-weight: 1000;
        font-size: 48px;
        line-height: 44px;
        color: white;
        font-weight: 500;
        font-family: "lotregular", "Impact", "Haettenschweilier", "Arial Black",
          monospaced;
      }
      .subtitle{
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
    @include breakpoint(M) {
      flex-wrap: wrap;
      .step{
        justify-content: center;
        padding: 3.625rem 0.625rem;
        width: 50%;
        border-top: 1px solid white;
        padding: 2rem 1rem;
        aspect-ratio: 1/1;
        h3{
          font-size: 35px;
          line-height: 44px;
        }
        .subtitle{
          font-size: 12px;
          line-height: 18px;
        }
        &:nth-of-type(even) {
          border-right: none;
        }
        &:nth-of-type(odd) {
          border-right: 1px solid white;
        }
      }
    }
  }
}
.image-content-standard-block{
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 5rem;
  padding-bottom: 6rem;
  display: flex;
  &.text-image{
    .image-content-image{
      order: 2;
      @include breakpoint(M) {
        margin-top: 1.938rem;
        margin-bottom: 0;
      }
    }
    .image-content-content{
      order: 1;
      padding-left: 0;
      padding-right: 6.5rem;
      @include breakpoint(M) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include breakpoint(S) {
    padding-left: 2.12rem;
    padding-right: 2.12rem;
  }

  @include breakpoint(M){
    flex-direction: column;
  }
  .image-content-image{
    width: 50%;
    padding-right: 1.063rem;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      @include breakpoint(S){
        aspect-ratio: 1/1;
      }
    }
    @include breakpoint(M){
      width: 100%;
      padding: 0;
      margin-bottom: 1.938rem;
    }
  }
  .image-content-content{
    width: 50%;
    padding-left: 6.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2, .h2{
      word-spacing: 0;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3rem;
    }
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 1rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    @include breakpoint(M){
      width: 100%;
      padding: 0;
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
}
.image-content-block{
  width: 100%;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  background: #DDF2F1;
  @include breakpoint(M){
      flex-direction: column;
  }
  @include breakpoint(S){
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .image-content-image{
    width: 50%;
    position: relative;
    @include breakpoint(M){
      width: 100%;
      height: 30rem;
      margin-bottom: 2rem;
    }
    .image-container{
      margin-right: 8.4375rem;
      position: relative;
      height: auto;
      aspect-ratio: 48 / 65;
      @include breakpoint(M){
        margin-right: unset;
        aspect-ratio: unset;
        height: 100%;
        width: 100%;
      }
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }
    
  }
  .image-content-content{
    width: 50%;
    @include breakpoint(M){
      width: 100%;
    }
    .top-content{
      margin-bottom: 3.375rem;
      p{
        font-weight: 600;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    .bottom-content{
      position: relative;
      .secondary-image-container{
        position: absolute;
        width: 23.6875rem;
        // height:32.3125rem;
        height: auto;
        aspect-ratio: 48 / 65;
        left: -15rem;
        z-index: 9;
        @include breakpoint(M){
          display: none;
        }
        img{
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
        @include breakpoint(M){
          aspect-ratio: unset;
          height: 100%;
          width: 100%;
        }
      }
      p{
        margin-left: 15rem;
        @include breakpoint(M){
          margin-left: unset;
        }
      }
    }
  }
}
.phone-quotes{
  width: 100%;
  background: $color-body;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 72rem;
  padding-left: 14.25rem;
  padding-right: 14.25rem;
  position: relative;
  @include breakpoint(M){
    padding-left: 5.25rem;
    padding-right: 5.25rem;
  }
  @include breakpoint(S){
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include breakpoint(XS){
    padding: 0;
  }
  .background-svg{
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    svg{
      width: 120%;
      height: 120%;
      position: relative;
      left: -10%;
    }
  }
  .phone-container{
      padding-top: 12.5em;
      padding-bottom: 6rem;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      gap:2.6rem;
      @include breakpoint(S){
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 5rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
      svg{
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        @include breakpoint(S){
          width: 90%;
          height: 90%;
        }
      }
      .title-card{
        width: 55%;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 0px;
        padding: 2.6666666666666665rem;
        position: relative;
        @include breakpoint(M){
          width: 100%;
        }
        &::after{
          content: "";
          position: absolute;
          bottom: -3.4rem;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3.5rem 3.5rem 0 0;
          border-color: #ffffff transparent transparent transparent;
        }
        h3{
          line-height: normal;
          margin-bottom: 0;
        }
        .star-icon{
          position: absolute;
          top: -3rem;
          width: 5.3rem;
          height: 5.3rem;
          z-index: 9;
          left: -3rem;
          @include breakpoint(S){
            left: 0;
            right: 0;
            margin: 0 auto;
          }
      }

      }
      .quotes-card-container{
        width:55%;
        align-self: flex-end;
        position: relative;
        min-height: 20rem;
        display: flex;
        align-items: center;
        transition: all 1s;
        @include breakpoint(M){
          width: 100%;
        }
        .quotes-controls{
          position: absolute;
          height: 100%;
          right: -2.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          top: 0;
          bottom: 0;
          gap: 1rem;
          @include breakpoint(M){
            right: 0;
            left: 0;
            bottom: -6rem;
            top: unset;
            display: flex;
            flex-direction: row;
            height: auto;
          }
          @include breakpoint(S){
            bottom: -3rem;
          }
          .js-arrow-next, .js-arrow-prev{
            width: 1.3rem;
            height: 1rem;
            cursor: pointer;
            svg{
              position: relative;
              width: 100%;
            }
          }
          .js-arrow-prev{
            transform: rotate(180deg);
            @include breakpoint(M){
              transform: rotate(90deg);
            }
          }
          @include breakpoint(M){
            .js-arrow-next{
              transform: rotate(270deg);
            }
          }
          .js-dots{
            display: flex;
            flex-direction: column;
            gap:0.5rem;
            @include breakpoint(M){
              flex-direction: row;
            }
            .js-dot{
              width: 1.3rem;
              height: 1.3rem;
              border: 2px solid #FFFFFF;
              border-radius: 100%;
              transition: all 0.5s;
              cursor: pointer;
              &.active{
                background-color: $color-accent-primary;
              }
              &:hover{
                background-color: $color-accent-primary;
              }
            }
          }
        }
        .quotes-card{
          width: 100%;
          background: #7ACDC8;
          border-radius: 12px 12px 0px 12px;
          padding: 2.6rem;
          position: relative;
          height:fit-content;
          animation: msg-zoom-in .5s; 
          transform: scale(0,0) translate(0, -200px);
          animation-fill-mode: forwards;

          &.active{

            animation: msg-zoom-in-text .5s;             
            transform: scale(0,0) translate(0, -200px);
            animation-fill-mode: forwards;

          }
          @keyframes msg-zoom-in {
            100% {
                transform: scale(1,1) translate(0, 0);
                transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000); 
            }
          }
          @keyframes msg-zoom-in-text {
            100% {
                transform: scale(1,1) translate(0, 0);
                transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000); 
            }
          }
          &::after{
            content: "";
            position: absolute;
            bottom: -3.4rem;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 3.5rem 3.5rem 0;
            border-color: transparent  #7ACDC8 transparent transparent;
          }
          .chat-bubble{
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            display: none;
            &.active{
              display: flex;
            }
            .loading{
              .dot{
                height: 17px;
                width: 17px;
                border-radius: 50%;
                background: #c1c1c1;
                display: block;
                float: left;
                margin: 0 0 0 8px;
                &:first-child{
                  margin: 0;
                }
                &.one{
                  animation: cycleOne 1s ease-in-out infinite;
                  animation-direction: normal;
                }
                &.two{
                  animation: cycleTwo 1s ease-in-out infinite;
                  animation-direction: normal;
                }
                &.three{
                  animation: cycleThree 1s ease-in-out infinite;
                  animation-direction: normal;
                }
              }
            }
          }
          
          @keyframes cycleOne{
            0%{
              background: rgba(255, 255, 255, 0.4);
            }
            33.333%{
              background: rgb(255, 255, 255);
            }
            66.6667%{
              background: rgba(255, 255, 255, 0.4);
            }
            100%{
              background: rgba(255, 255, 255, 0.4);
            }
          }
          
          @keyframes cycleTwo{
            0%{
              background: rgba(255, 255, 255, 0.4);
            }
            33.333%{
              background: rgba(255, 255, 255, 0.4);
            }
            66.6667%{
              background: rgba(255, 255, 255, 1);
            }
            100%{
              background: rgba(255, 255, 255, 0.4);
            }
          }
          
          @keyframes cycleThree{
            0%{
              background: rgba(255, 255, 255, 0.4);
            }
            33.333%{
              background: rgba(255, 255, 255, 0.4);
            }
            66.6667%{
              background: rgba(255, 255, 255, 0.4);
            }
            100%{
              background: rgba(255, 255, 255, 1);
            }
          }
          .single-quote{
            text-align: right;
            display: none;
            flex-direction: column;
            &.active{
              display: flex;
            }
            .author{
              color: #000000;
              font-weight: 600;
              margin-bottom: 0;
            }
            .company{
              color: #FFFFFF;
              margin-bottom: 0;

            }
          }
          .quote-text{
            p{
              color: #FFFFFF;
            }
          }

        }
    }
  }
}
.grid-services{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  @include breakpoint(S){
    // grid-template-columns: 1fr;
    grid-template-columns: 50% 50%;
  }
  .service{
    padding:5.5rem;
    display: flex;
    flex-direction: column;
    h2{
      color: $color-body;
      display: flex;
      flex-direction: column;
      .subtitle{
        transform: unset;
        padding: unset;
        background: unset;
        display: flex;
        margin-left: unset;
      }
    }
    .service-image{
      width: 7rem;
      height: 7rem;
      position: relative;
      margin-bottom: 3.5rem;
      svg{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    svg{
      color: $color-body;
    }
    .button-link{
      transition: all 0.5s;
    }
    &:hover{
      .button-link{
        transform: translateX(0.25rem);
      }
    }
    @include breakpoint(S){
      padding: 3rem 2rem;
    }
  }
}
.logo-collection-autosize{
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  @include breakpoint(S){
    padding-left: 0;
    padding-right: 0;
  }
  h4{
    margin-bottom: 5rem;
    text-align: center;
  }
  .logo-container{
    display: flex;
    @include breakpoint(M){
      display: flex;
      flex-direction: row;
    }
    .logo-image{
        height: auto;
        width: 100%;
        position: relative;
        // @include breakpoint(M){
        //   width: 5rem;
        //   flex:none;
        // }
        img{
          width: 100%;
          height: 100%;
          max-height: 50px;
          position: relative;
          object-fit: contain;
        }
        @include breakpoint(S){
          margin: 0 0.5rem;
        }
    }
  }
}
.logo-collection{
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  h4{
    margin-bottom: 5rem;
    text-align: center;
  }
  .logo-container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap:5.4rem;
    @include breakpoint(M){
      display: flex;
      flex-direction: row;
      overflow: scroll;
    }
    .logo-image{
        height: 6.5rem;
        position: relative;
        @include breakpoint(M){
          width: 5rem;
          flex:none;
        }
        img{
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: contain;
        }
    }
  }
}

.get-a-quote-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  height: 100vh;

  @include breakpoint(M) {
    height: auto;
  }

  .get-a-quote-content {
    grid-column: 1 / 7;
    background-color: $color-accent-primary;
    padding: 9rem 11.25rem 2rem 6rem;
    position: relative;

    @include breakpoint(M) {
      grid-column: 1 / 13;
      padding: 8rem 2rem 4.5rem 2rem;
    }

    .get-a-quote-icon {
      position: absolute;
      bottom: 0;
      left: 0;

      @include breakpoint(M) {
        display: none;
      }
    }

    h1 {
      font-weight: 700;
      margin-bottom: 3rem;
    }

    p {
      font-size: 21px;
    }

    .contact-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .contact {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 21px;
        color: $color-body;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 2.5rem;
          &::after {
            content: '';
            position: absolute;
            bottom: -1.25rem;
            left: 4.5rem;
            height: 1px;
            width: 45px;
            background-color: $color-body;
          }
        }


        @include breakpoint(M) {
          font-size: 1rem;
        }

        .icon {
          border-radius: 50%;
          min-width: 2.75rem;
          min-height: 2.75rem;
          margin-right: 1.75rem;
          background-color: $color-white;
          padding: 14px;
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .get-a-quote-form {
    grid-column: 7 / 13;
    padding: 9rem 5.625rem 2rem 5.625rem;

    @include breakpoint(M) {
      grid-column: 1 / 13;
      padding: 4.5rem 2rem;
    }
    
    .hidden_label{
      display: none;
    }

    .gform_fields {
      display: grid;
      grid-template-columns: repeat(2, minmax(250px, 1fr));
      grid-column-gap: 2.625rem;
      grid-row-gap: 64px;

      @include breakpoint(M) {
        grid-template-columns: 1fr;
        grid-row-gap: 32px;
      }

      .gfield {
        &--width-half {
          grid-column: span 1;

          @include breakpoint(M) {
            grid-column: 1 / -1;
          }
        }
        &--width-full {
          grid-column: span 2;

          @include breakpoint(M) {
            grid-column: 1 / -1;
          }
        }

        .gfield_label {
          font-weight: 700;
        }

        input, textarea {
          padding-left: 0;
        }

        textarea {
          border: none;
          border-bottom: 2px solid $color-tertiary;
          height: 7.5rem;
        }
      }
    }
  }
}

.thank-you-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding-left: 9.875rem;
  padding-right: 9.875rem;

  @include breakpoint(M) {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .thank-you-image {
    margin-right: 96px;

    @include breakpoint(M) {
      order: 1;
      margin-right: 0;

      svg {
        width: 218px;
        height: 218px;
      }
    }

    svg {
      .circle {
        transform: translateY(0);
        animation: moveDown 2s ease 1;
      }
      
      .svg-elem {
        &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9 {
          animation: blink 1.5s linear infinite;
        }
        
        &-1, &-2, &-3, &-4, &-5, &-6{
          animation-delay: 0.75s;
        }
        
        &-10 {
          stroke-dasharray: 93.6053695678711px;
          stroke-dashoffset: 0;
          animation: fill-10 3s ease 1;
        }
        
        
        &-11 {
          stroke-dasharray: 62.87664794921875px;
          // stroke-dashoffset: 0;
          animation: fill-11 3s ease 1;
        }
        &-12 {
          // stroke-dashoffset: 0;
          stroke-dasharray: 62.87236785888672px;
          animation: fill-12 3s ease 1;
        }
        &-13 {
          // stroke-dashoffset: 0;
          stroke-dasharray: 94.0103759765625px;
          animation: fill-13 3s ease 1;
        }
        &-14 {
          // stroke-dashoffset: 0;
          stroke-dasharray: 62.812259674072266px;
          animation: fill-14 3s ease 1;
        }
        &-15 {
          // stroke-dashoffset: 0;
          stroke-dasharray: 62.845821380615234px;
          animation: fill-15 3s ease 1;
        }
      }
    }
  }

  .thank-you-content {
    @include breakpoint(M) {
      order: 0;
      text-align: center;
      margin-bottom: 63px;
    }
    h2 {
      font-weight: 700;
      position: relative;
      margin-bottom: 48px;

      @include breakpoint(M) {
        margin-bottom: 64px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 200px;
        height: 2px;
        bottom: -48px;
        left: 0;
        background-color: $color-body;

        @include breakpoint(M) {
          bottom: -32px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .button {
      margin-top: 48px;

      &.dark {
        border-color: $color-body;
        color: $color-body;

        &:hover {
          border-color: $color-accent-primary;
          color: $color-white;
        }

        @include breakpoint(M) {
          margin: 0 auto;
          width: 75%;
        }
      }
    }
  }
}

@keyframes moveDown {
  0% {
    opacity: 0;
    transform: translateY(-800px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fill-10 {
  0% {
    stroke-dashoffset: 93.6053695678711px;
    opacity: 0;
  }
  
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}
@keyframes fill-11 {
  0% {
    stroke-dashoffset: 62.87664794921875px;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-12 {
  0% {
    stroke-dashoffset: 62.87236785888672px;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-13 {
  0% {
    stroke-dashoffset: 94.0103759765625px;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-14 {
  0% {
    stroke-dashoffset: 62.812259674072266px;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-15 {
  0% {
    stroke-dashoffset: 62.845821380615234px;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}