// Configuring Susy 2 Global Defaults
%container {
  width: 100%;
  padding-left: calc(1.5rem + 2.5vw);
  padding-right: calc(1.5rem + 2.5vw);

  @include breakpoint(XL) {
    padding-left: calc(5rem + 2.5vw);
    padding-right: calc(5rem + 2.5vw);
  }

  @include breakpoint(XS) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.container {
  @extend %container;
}

.container-capped {
  max-width: 80rem;
  width: 100%;
  padding-left: calc(1.5em + 2.5vw);
  padding-right: calc(1.5em + 2.5vw);
  margin: 0 auto;
  
  @include breakpoint(XL) {
    padding-left: calc(5em + 2.5vw);
    padding-right: calc(5em + 2.5vw);
  }
}

.half {
  width: 50%;

  @include breakpoint(S) {
    width: 100%;
    float: none;
  }
}
