footer,
footer.footer {
  text-align: center;
  padding-top: 8em;
  position: relative;
  overflow: hidden;

  @include breakpoint(S) {
    padding-bottom: 1em;
  }
  .footer-img-wrap {
    position: absolute;
    display: grid !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .footer-logo {
      place-self: start center;
      width: min(100%, 24rem);
      height: auto;
      aspect-ratio: 1;

      @include breakpoint(S) {
        margin-top: 3rem;
      }
    }
  }

  p {
    color: $color-body;
    font-size: 1.5em;
    line-height: 2;
    padding: 0 1em;
    margin-bottom: 4em;

    @include breakpoint(S) {
      font-size: 1.25em;
      line-height: 3;
      margin-bottom: 5em;
    }
    @include breakpoint(XS) {
      line-height: 2;
      margin-bottom: 1rem;
    }
    a {
      margin: 0 0.5em;
      color: $color-body;

      &.underline {
        border-bottom: 2px solid $color-body;

        &:hover {
          border-bottom: 2px solid $color-tertiary;
        }
      }

      svg {
        display: inline;
      }
      &.social {
        &:hover {
          transform: translateY(-0.5em);
        }
      }
    }
  }

  .footer-menu {
    display: none;
    font-family: $font-stack-secondary;
    font-size: 1.5em;
    letter-spacing: 6px;
    color: $color-body;
    border: 1px solid $color-body;
    z-index: 9;
    line-height: 1;
    padding: 0.8em 0 0.6em 0.3em;
    background: #fff;
    margin: 0 1em 1em 1em;

    @include breakpoint(S) {
      display: block;
    }
  }

  .menu {
    text-align: center;
    margin: 0;
    padding: 0;
    border-top: 2px solid $color-tertiary;

    li {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      @include breakpoint(S) {
        display: block;
      }

      &:before {
        display: none;
      }

      a {
        color: $color-tertiary;
        font-size: 1.5em;
        padding: 1em 0.5em 0.8em 0.5em;
        display: block;

        @include breakpoint(S) {
          padding: 0.5em 0.5em 0.3em 0.5em;
        }

        &:hover {
          color: $color-body;
        }
      }
    }
  }
  .partners {
    margin-bottom: 2em;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(S) {
      flex-direction: column;
    }
    > img,
    > div,
    > svg {
      margin: 0 2rem !important;

      @include breakpoint(S) {
        margin: 1rem auto !important;
      }
    }
  }
}

.quote-sidebar {
  @extend %transition;
  width: 28rem;
  max-width: 100%;
  background: #fff;
  z-index: 110;

  @media (max-height:840px) and (min-width:640px) {
    width: 38rem;
  }
  @include breakpoint(XS) {
    width: 100%;
  }
  &.open {
    transform: translateX(0);
  }
  .quote-sidebar-inner {
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5rem 3rem 2rem 4rem;

    @include breakpoint(XS) {
      padding: 5rem 2rem 2rem 2rem;
    }
  }
  .quote-sidebar-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    font-weight: bold;
    opacity: 0.5;
    z-index: 1;
    color: $color-body;

    &:hover {
      opacity: 1;
    }
    svg {
      margin-left: 0.5rem;
    }
  }
  .quote-sidebar-content {
    padding-left: 3.5rem;
    margin-bottom: 3.1875rem;

    @include breakpoint(XS) {
      padding-left: 10rem;
    }
    h3 {
      text-transform: none;
      letter-spacing: normal;
      line-height: 2.25rem;
      margin-bottom: 0.25rem;
    }
    p {
      margin: 0;
    }
  }
  .quote-sidebar-icon {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-accent-primary;
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 4.825rem;
    left: 0;
    @extend %transition;
    transform: translateX(-2rem);

    @include breakpoint(XS) {
      transform: translateX(2rem);
    }
    svg {
      display: block;
    }
  }
  label {
    font-size: 0.75rem;
    font-weight: 700;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border: 0;
    background: none;
    margin-bottom: 1.5rem;
    padding: 0.25rem 0 0.5rem 0;
    border-bottom: 2px solid $color-body;
  }
  textarea {
    height: 3.75rem;
  }
  .recaptcha {
    margin-bottom: 1.3125rem;
    display: block;
  }
  .button {
    margin-bottom: 0;
  }
  div.wpcf7 {
    margin: 0;

    p {
      margin: 0;
    }
  }
  @media (max-height:840px) and (min-width:640px) {
    .quote-form-fields {
      display: flex;
      flex-wrap: wrap;

      p {
        width: calc(50% - 1.5rem);

        &:nth-child(odd) {
          margin-right: 0.75rem;
        }
        &:nth-child(even) {
          margin-left: 0.75rem;
        }
        &:last-child {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  &.scrolled {
    .quote-sidebar-icon {
      transform: translateX(-6rem);

      @include breakpoint(XS) {
        transform: translateX(2rem);
        opacity: 0.1;
      }
    }   
  }
}

.quote-sidebar-bg {
  position: fixed;
  z-index: 109;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-110%);
  @extend %transition;
  background: rgba($color-body, 0.9);
  cursor: crosshair;

  &.open {
    transform: translateX(0);
  }
}

// Conversion tools
.exitcapture-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
  background: rgba(0,0,0, .5);
}

.exitcapture-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;

  .exitcapture-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  .modal {
      height: 80%;
      max-height: 40rem;
      max-width: 40rem;
      width: 100%;
      position: relative;
      z-index: 101;
      background-color: $color-background;

      .modal-content {
          padding: 6rem 3.5rem 4rem 3.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .mb-8{
            p:last-of-type{
              margin-bottom: 0;
            }
          }

          @include breakpoint(XS) {
              padding: 5rem 1.5rem 2rem 1.5rem;
          }
      }
      .exitcapture-close {
        cursor: pointer;
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
      }
      &.has-image {
          max-width: 56rem;
          
          .modal-img,
          .modal-content {
              width: 50%;

              @include breakpoint(S) {
                  width: 100%;
              }
          }
          .modal-img {
              position: relative;
              &.top{
                  img{
                      object-position: top;
                  }
              }
              &.center{
                  img{
                      object-position: center;
                  }
              }
              &.bottom{
                  img{
                      object-position: bottom;
                  }
              }
              img{
                  height: 100%;
              }
              @include breakpoint(S) {
                  display: none;
              }
          }
      }
  }
}

.countdown {
  .countdown-item {
      // background-color: blue;
      color: $color-body;
      background: var(--color-accent-primary-lightest);
      border-radius: 0.5rem;
      font-weight: 700;
      padding: 0.5rem 0.75rem;
      // @include size-L;
      margin: 0 0.125rem;

      @include breakpoint(XS) {
          text-transform: uppercase;
          letter-spacing: 0.125em;
          // @include size-S;
          padding: 0.25rem 0.65rem;
      }
  }
}

.slide-callout {
  display: none;
  position: fixed;
  right: 0;
  bottom: 8rem;
  z-index: 10;
  width: 16rem;
  background-color: $color-background;
  border: 2px solid;
  border-color: $color-body;
  border-right: 0;
  @extend %transition;
  transition-delay: 0.3s;

  .slide-callout-title {
      position: relative;

      h3 {
          padding: 1rem 0.5rem 1rem 1.25rem;
          margin-bottom: 0;
      }
      .slide-callout-close {
          border-left: 1px solid;
          border-color: $color-body;
          padding: 0 1rem;
          pointer-events: none;

          svg {
              @extend %transition;
          }
      }
      &:after {
          // blanker over the top of the close button to stop clicks until it's open
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 4rem;
          z-index: 1;
          transition-delay: 0s;
      }
  }
  .slide-callout-inner {
      max-height: 0;
      @extend %transition;
      padding: 0 1.25rem;
      overflow: hidden;
      overflow-y: scroll;
      opacity: 0;
      border-top: 1px solid;
      border-color: $color-body;
      transition-delay: 0s;

      p {
          padding: 1.5rem 0;
          font-size: 1rem;
          line-height: 1.5;
      }
      p.no-margin{
        margin: 0;
      }
      ul{
        margin-bottom: 0;
      }
      li {
          // @include size-S;
          padding: 0.75rem 0;
          border-bottom: 1px solid;
          border-color: $color-body;
          font-size: 1rem;
          line-height: 1.5;

          svg {
              color: $color-accent-primary;
              margin-right: 0.75rem;
          }
          &::before{
            display: none;
          }
          &:first-child {
              padding-top: 0;
          }
          &:last-child {
              border: 0;
              padding-bottom: 1.5rem;
          }
          &:last-of-type{
            margin-bottom: 0;
          }
      }
      .button{
        border: 2px solid;
        border-left: none;
        border-bottom: none;
      }
  }
  .button {
      width: calc(100% + 2.5rem);
      margin-left: -1.25rem;
      margin-right: -1.25rem;
  }
  h3,
  .slide-callout-close svg {
      color: $color-accent-primary;
  }
  &:hover {
      width: 20rem;
      transition-delay: 0s;

      h3,
      .slide-callout-close svg {
          color: $color-body;
      }
      .slide-callout-close svg {
          display: block;
          transform: rotate(45deg);
      }
      .slide-callout-inner {
          max-height: 20rem;
          opacity: 1;
          transition-delay: 0.3s;
      }
      .slide-callout-close {
          pointer-events: auto;
      }
      .slide-callout-title:after {
          transform: translateX(150%);
          transition-delay: 0.5s;
      }
  }
}

.highlight-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding-left: 2rem;
  background-color: $color-accent-primary;
  border-top: 2px solid;
  border-color: $color-body;

  @include breakpoint(XS) {
      padding-left: 0;
      padding-right: 1.5rem;

      .countdown {
          .countdown-item:first-child {
              margin-left: 0;
          }
      }
  }
  .highlight-bar-inner {
      padding: 0.5rem 0.5rem 0.5rem 0;
      .h4{
        margin-bottom: 0;
        color: $color-body;
      }
      @include breakpoint(XS) {
          padding: 0.75rem 0 0.75rem 1.5rem;
          max-width: 60%;
      }
  }
  .countdown {
      margin-left: 2rem;

      @include breakpoint(XS) {
          margin: 0.5rem 0 0 0;
      }
  }
  .button {
      display: flex !important;
      align-items: center;
      justify-content: center;
      border: none;
      color: $color-accent-primary;
      background-color: $color-body;
      height: 100%;
  }
  .highlight-bar-close {
      width: 3.5rem;
      color: $color-body;
      padding: 0.5rem;

      @include breakpoint(XS) {
          position: absolute;
          right: 0.5rem;
          top: -1.5rem;
          border-radius: 100%;
          border: 1px solid;
          border-color: $color-accent-primary;
          width: 2.25rem;
          height: 2.25rem;
          background-color: green;
          z-index: 1;

          svg {
              width: 18px;
              height: 18px;
          }
      }
      &:hover {
          background-color: $color-body;
          svg{
            color: $color-background;
          }
      }
  }
}