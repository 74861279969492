//Styling for the Rugby web design contact form
.contact-rugby-web-design_wrapper{
    background-color:$color-body;
    color:$color-white;
    font-family: $font-stack-primary;
    min-width: calc(100% + 1.5rem);
    position:relative;
    // left: -0.75rem;
    left: 0;
    padding: 5rem 14.25rem;
    text-align: center;
    @include breakpoint(M){
        padding-left: 5.25rem;
        padding-right: 5.25rem;
      }
      @include breakpoint(S){
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @include breakpoint(XS){
        padding: 1rem;
      }
    h2.gform_title{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1;
        word-spacing: 0.125em;
    }
    .gform_required_legend{
        display:none;
    }
    form.contact-rugby-web-design{
        margin-top:5rem;
        .gform-body .gform_fields {
            grid-column-gap: 4%;
            grid-row-gap: 3rem;
            .contact-field{
                border-bottom: 1px solid $color-white;
                input{
                    background-color:$color-body;
                    color:$color-white;
                    font-size: 1rem;
                }
            }
            .ginput_container_textarea textarea{
                background-color:$color-body;
                color:$color-white;
                font-size: 1rem;
            }
            fieldset.contact-checkboxes .ginput_container_checkbox,
            fieldset.contact-checkboxes .ginput_container_radio {
                margin-top:1.25rem;
                .gfield_checkbox,
                .gfield_radio{
                    display:inline-flex;
                    .gchoice {
                        margin:0 10px;
                        input{
                            padding:8px;
                            position: relative;
                            height: 10px;
                            width: 10px;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            -o-appearance: none;
                            appearance: none;
                            border: 1px solid $color-white;
                            outline: none;
                            background-color: $color-body;
                            cursor: pointer;
                            margin-bottom: 0;
                            &:checked{
                                background-color: $color-accent-primary;
                            }
                        }
                    }
                }
            }
        }
        .gfield_label{
            font-size: 0.9rem;
            line-height: 1.5;
            .gfield_required{
                .gfield_required.gfield_required_asterisk{
                    color:$color-accent-secondary;
                }
            }
        }
        .ginput_container.ginput_container_text input{
            border-bottom:none;
        }
        .ginput_container.ginput_container_textarea textarea{
            border:1px solid $color-white;
        }
        .gform_footer{
            display: inline-flex;
            padding:0;
            margin-top:3rem;
            .gform_button{
                padding: 1rem 1.5rem;
            }
        }
        .contact-captcha{
            display:inline-flex;
            justify-content: center;
            label{
                color:$color-body;
                width:0px;
            }

        }

    }

}
