.elements-container {
    padding-left: calc(1.5em + 2.5vw);
    padding-right: calc(1.5em + 2.5vw);
    margin: 0 auto;
    position: relative;

    @include breakpoint(XL) {
        padding-left: calc(5em + 2.5vw);
        padding-right: calc(5em + 2.5vw);
    }
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &.standard-margin {
        margin-bottom: 1.5em;
    }
    &.margin-top {
        margin-bottom: 0;
        margin-top: 1.5em;
    }
    &.top-bottom-margin {
        margin-bottom: 1.5em;
        margin-top: 1.5em;
    }
    &.no-margin {
        margin-bottom: 0;
        margin-top: 0;
    }
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.row-breakout {
      padding: 0;
      max-width: none;

      .row,
      .elements-row {
        margin-left: 0;
        margin-right: 0;
      }
      .col:first-child {
        padding-left: 0;
      }
      .col:last-child {
        padding-right: 0;
      }
    }
    &.padd-both {
      padding-top: 4.5em;
      padding-bottom: 3em;
    }
    &.padd-top {
      padding-top: 4.5em;
    }
    &.padd-bottom {
      padding-bottom: 3em;
    }
    &.capped {
      max-width: 72em;
    }
  }

  .elements-row {
    margin: 0 -0.75em;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(S) {
      margin: 0;
      display: block;
    }
    .element {
    //   margin: 0 0.75em;
      margin: 0;
      flex-shrink: 0;
      position: relative;

      @include breakpoint(S) {
        margin: 0 0 1.5em 0;
      }
      &.full-width {
        width: calc(100% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
        &.single-image {
            width: 100%;
            margin: 0;
        }
      }
      &.half {
        width: calc(50% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
      }
      &.quarter {
        width: calc(25% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
      }
      &.third {
        width: calc(33.333% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
      }
      &.two-thirds {
        width: calc(66.666% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
      }
      &.three-quarters {
        width: calc(75% - 1.5em);

        @include breakpoint(S) {
          width: 100%;
        }
      }
    }
}

.single-image {
    img {
        margin: 0 auto;
    }
    &.img-full {
        img {
            width: 100%;
        }
    }
    &.contain {
        img {
            object-fit: contain;
            font-family: 'object-fit: contain;';
        }
    }
    .image-caption {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.125em;
        padding-top: 1em;
        letter-spacing: 2px;
    }
}

.content {
    &.align-middle {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    &.align-bottom {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 1rem;
    }
    &.white-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li,
        p {
            color: #fff;
        }
    }

    div {
        width: auto;
        max-width: 100%;
    }

    .wp-caption img {
        max-width: 100%;
        height: auto;
    }
}

.image-stack {
    display: flex;
    flex-direction: column;

    img {
        flex-grow: 1;
        margin: 0.75em 0;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.next-proj {
    position: relative;
    text-align: center;
    color: #fff;
    display: block;
    padding: 5em 1em;

    p:last-child {
        margin: 0;
    }
    h2 {
        margin-bottom: 0.5em;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        z-index: 2;
        opacity: 0.9;
    }
    .proj-link-content {
        position: relative;
        z-index: 3;
    }
    .excerpt {
        font-size: 1.5em;
        margin: 0 auto 0.5em auto;
        max-width: 42em;

        @include breakpoint(S) {
            font-size: 1em;
        }
    }
}

.image-panels {
    display: flex;

    .img-panel {
        position: relative;
        min-height: 30em;
        margin: 0.5em;
        flex-grow: 1;
        @extend %transition;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    &.vert {
        .offset-top {
            transform: translateY(-2em);
        }
        .offset-bottom {
            transform: translateY(2em);
        }
        &:hover {
            .img-panel {
                transform: translateY(-0.5em);

                &:nth-child(even) {
                    transform: translateY(0.5em);
                }
            }
        }
    }
    &.horz {
        flex-direction: column;

        .img-panel {
            min-height: 8em;
        }
        .offset-left {
            transform: translateX(-2em);
        }
        .offset-right {
            transform: translateX(2em);
        }
        &:hover {
            .img-panel {
                transform: translateX(-0.5em);

                &:nth-child(even) {
                    transform: translateX(0.5em);
                }
            }
        }
    }
}

.element.content {
    &.left-offset {
        padding-left: 3em;

        @include breakpoint(S) {
            padding-left: 0;
        }
    }
    &.right-offset {
        padding-right: 3em;

        @include breakpoint(S) {
            padding-right: 0;
        }
    }
}

.image-showcase-wrapper {
    width: 100%;
    margin: 0 !important;

    .img-showcase {
        width: 100%;
        padding: 1em 0;

        img {
            display: inline-block;
            max-width: 75%;
        }
        &.img-centre {
            text-align: center;
        }
        &.img-right {
            text-align: right;
        }
    }
}

.image-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .img-grid {
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        &.faded {
            img {
                transform: scale(0.8);
                opacity: 0.25;
            }
        }
    }
}

.stats {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;

        &.grey-circle {

            .circle-container {
                border-color: $color-body;
            }
        }

        &.white-circle {

            .circle-container {
                border-color: #fff;
            }
        }

        &.grey-text {
            color: $color-body;
            .stat {

                p {
                    color: $color-body;
                }
            }
        }

        &.white-text {
            color: #fff;
            .stat {

                p {
                    color: #fff;
                }
            }
        }

    .counter:before {
      content:"+"
    }

    .counter:after {
      content:"%"
    }

    .circle-container {
        height: 4.5em;
        margin-bottom: 0.25em;
        width: 4.5em;
        position: relative;
        background: transparent;
        border: solid 1px #fff;
        border-radius: 100%;
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(M) {
            width: 5.3em;
            height: 5.3em;
        }
        .stat-circle {
          width: 100%;
          &.svg-container {
            width: 100%;

            svg {
              circle {
                display: none;
              }
              .timer {
                fill: #fff;
                font-size: 42px!important;
              }
            }
          }
        }
    }

    .stat {
        margin: 0 1.2em;
        position: relative;
        @include breakpoint(M) {
            margin: 0 1.2em 1em 1.2em;
        }
         p {
            position: absolute;
            font-size: 12px;
            bottom: 40px;
            left: 50%;
            width: 70%;
            transform: translateX(-50%);
            margin: 0;
            @include breakpoint(M) {
                bottom: 45px;
            }
        }
        &.plus {
            .counter {
                &:before {
                    content: "+";
                }
            }
        }

        &.minus {
            .counter {
                &:before {
                    content: "-";
                }
            }
        }
        .counter {
            margin-bottom: 3.5rem;
        }
    }
}
