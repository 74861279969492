// Color SCSS defs
$color-body: #181818; // dark grey
$color-accent-primary: #7acdc8; // mint
$color-accent-secondary: #e76780; // pink
$color-tertiary: #908f8f; // light grey
$color-background: #fff;

$color-green: #7acdc8;
$color-yellow: #efb851;
$color-orange: #f3843f;
$color-red: #d76062;
$color-blue: #3594c9;
$color-white: #fff;