//Styling for the Image & Content Block

.img-content {
    --image-offset: 12rem;
    --section-spacing: 6rem;
    --inner-spacing: 6rem;

    @include breakpoint(XS) {
        --section-spacing: 3rem;
        --inner-spacing: 2rem;
        --image-offset: 3rem;
    }


    background: $color-body;
    color: #fff;
    padding-top: var(--inner-spacing);
    padding-bottom: var(--inner-spacing);
    margin-top: -5rem;
    margin-bottom: var(--image-offset);
    display: grid;
    gap: 2rem;
    
    @include breakpoint(S-up) {
        grid-template-columns: repeat(12, 1fr);
    }
}

.img-content__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(S-up) {
        grid-column: 1 / span 6;
    }

    @include breakpoint(M-up) {
        grid-column: 1 / span 5;
    }
}

.img-content__title {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.img-content__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 105%;

    @include breakpoint(S-up) {
        grid-column: 7 / span 6;
        margin-bottom: calc(var(--image-offset) * -1);
        order: 2;
    }

    @supports (aspect-ratio: 1/1) {
        height: auto;
        aspect-ratio: 73/76;
        padding-bottom: unset;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

//styling for img content on work page & why us page

    .img-content-work{
        display: flex;
        &.image-content{
            flex-direction: row-reverse;
            @include breakpoint(S){
                flex-direction: column;
            }
        }
        @include breakpoint(S){
            flex-direction: column;
        }

        .button{
            width: fit-content;
            margin-top: 1rem;
        
        }

        .img-content__content{
            width: 50%;
            padding-top: 9.3125rem;
            padding-bottom: 9.3125rem;
            padding-left: 7.5rem;
            padding-right: 7.5rem;
            @include breakpoint(S){
                width:100%;
                padding-left: 3rem;
                padding-right:3rem;
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;
            }
        }
        
        .img-content__image{
            width: 50%;
            position: relative;
            @include breakpoint(S){
                width:100%;
            }
        }
        &.extra-bottom{
            margin-bottom: 6.5rem;
            @include breakpoint(S){
                margin-bottom: 0;
            }
        }
    }
