.post-listing {
  max-width: 75em;
  margin: 0 auto;
  padding: 0 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.post-link {
  border: 2px solid $color-body;
  background: $color-accent-primary;
  width: 49%;
  color: $color-body;
  padding: 1em;
  font-size: 2.25em;
  position: relative;
  margin-bottom: 2%;
  min-height: 10em;

  @include breakpoint(S) {
    width: 100%;
    margin-bottom: 0.5em;
  }

  .date {
    position: absolute;
    font-weight: bold;
    text-transform: uppercase;
    bottom: 1em;
    right: 1em;
    font-size: 0.5em;

    @include breakpoint(S) {
      display: block;
      position: static;
    }
  }
  &:hover {
    background: $color-body;
    color: $color-accent-primary;
  }
  &.has-image {
    position: relative;
    margin-right: 51%;
    margin-top: 1em;
    margin-bottom: calc(2% + 1em);

    @include breakpoint(S) {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    img {
      position: absolute;
      top: -1em;
      bottom: -1em;
      left: calc(100% - 1em);
      z-index: -1;
      max-width: none;
      height: calc(100% + 2em);
      width: calc(100% + 1em);
      z-index: -1;

      @include breakpoint(S) {
        position: static;
        width: 100%;
        height: auto;
        margin-top: 0.5em;
      }
    }

    &:nth-child(odd) {
      margin-right: 0;
      margin-left: 51%;

      @include breakpoint(S) {
        margin-left: 0;
      }

      img {
        left: auto;
        right: calc(100% - 1em);
      }
    }
  }
}

.news-banner {
  position: relative;

  img {
    width: 100%;
  }
}

.loadmore-posts {
  text-align: center;
  margin-top: 2em;

  a {
    font-size: 2.25em;
    padding-bottom: 0.25em;
    padding-top: 0.4em;

    &:before {
      height: 2px;
    }
  }
}

.single-jobs {
  .jobs-top {
    background: $color-body;
    padding: 5em 0;
    color: #fff;
  }
  .jobs-bottom {
    padding: 5em 2em;
  }
  .nav-toggle {
    background: $color-body;
    color: #fff;
    border: 1px solid #fff;
  }
}

.share {
  padding-top: 1rem;
  text-align: center;
  
  p:first-child {
    margin-bottom: 1em;
  }
  .share-links {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
          width: 1.5em;
          height: 1.5em;

          path {
              @extend %transition;
          }
      }
      a {
          margin: 0 0.75em;

          &:before,
          &:after {
            display: none;
          }
          &:hover {
              svg {
                  path {
                      fill: $color-body;
                  }
              }
          }
      }
  }
}