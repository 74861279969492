a,
input,
textarea,
button {
  @extend %transition;
}

* {
  box-sizing: border-box;
}

.cf:before,
.cf:after,
.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after,
.clearfix:after {
  clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf,
.clearfix {
  *zoom: 1;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  accent-color: var(--color-accent-primary);
}

.object-fit {
  object-fit: cover;
  font-family: "object-fit: cover;";

  &.contain {
    object-fit: contain;
    font-family: "object-fit: contain;";
  }
}

input,
textarea {
  border: 2px solid $color-tertiary;
  padding: 1em 1em 0.6em 1em;
  font-size: 1em;
  width: 100%;
  border-radius: 0;
  margin-bottom: 1em;
  box-shadow: none;
  color: $color-body;
  font-weight: 300;
  font-family: $font-stack-primary;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  @include placeholder {
    color: #999;
  }

  &:focus {
    outline: 0;
    border: 2px solid var(--color-accent-primary);
  }
  &[type="text"] {
    appearance: none;
  }

  &.wpcf7-not-valid {
    color: #ff523d;
  }
}

textarea {
  padding: 2em 1em 1.6em 1em;
}

.content-container .button,
.content-container button,
.button,
button,
input[type="submit"],
p .button,
.container .button {
  border: 0;
  font-weight: 700;
  border-radius: 0;
  background: transparent;
  appearance: none;
  color: var(--color-body);
  border: 3px solid var(--color-body);
  padding: 1rem 1.5rem 0.75rem;
  cursor: pointer;
  width: auto;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 3;
  font-family: $font-stack-primary;
  &.lrg {
    font-size: 1.5em;
    padding: 0.6em 1.25em;
  }
  &.xl {
    font-size: 1.75em;
    padding: 0.5em 1.25em;
  }
  &:before {
    display: none;
  }
  &:not(.minor):after {
    content: "";
    display: block;
    background: var(--color-body);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    @extend %transition;
  }
  &:hover {
    color: var(--color-background);

    &:after {
      width: 100%;
    }
  }
  &:focus {
    outline: 0;
  }
  &.outline {
    border-color: #fff;
    background: var(--color-accent-primary);
    color: #fff;

    &:after {
      background: #fff;
    }
    &:hover {
      color: var(--color-accent-primary);
    }
  }
  &.new {
    font-weight: bold;
    padding: 0.75em 1em 0.875em 1em;
    font-size: 1rem;
    letter-spacing: normal;
    border-width: 3px;
  }
  &.minor {
    padding: 0;
    color: var(--color-body);
    font-size: 0.875rem;
    font-weight: 700;
    border: 0;
    gap: 0.5rem;

    path {
      fill: var(--color-body);
    }

    &:hover {
      border: 0;
      filter: brightness(3);

      &:after {
        background: transparent;
      }

      path {
        fill: var(--color-body);
      }
    }
  }
  &.cta {
    padding: 0.75em 1.3em 0.5em 1.3em;

    svg,
    svg path {
      @extend %transition;
    }
    &:hover {
      svg {
        transform: translateX(0.25em);

        path {
          fill: var(--color-background);
        }
      }
    }
  }
  &.white {
    color: $color-white;
    border-color: $color-white;

    &:after {
      background: $color-white;
    }

    &:hover {
      color: var(--color-body);

      path {
        fill: var(--color-body);
      }
    }
    svg{
      fill: var(--color-white);
    }
  }
  &.black {
    color: #000;
    border: 2px solid #000;
    width: fit-content;
  }
  &.orange {
    color: #efb851;
    border: 2px solid #efb851;
    width: fit-content;
    &.icon-right {
      width: fit-content;
      svg,
      svg path {
        @extend %transition;
        fill: #efb851;
      }
    }
  }
  &.red {
    color: #D76062;
    border: 2px solid #D76062;
    width: fit-content;
    &.icon-right {
      svg,
      svg path {
        @extend %transition;
        fill: #D76062;
      }
    }
  }
  &.blue {
    color: #3594C9;
    border: 2px solid #3594C9;
    width: fit-content;
    &.icon-right {
      svg,
      svg path {
        @extend %transition;
        fill: #3594C9;
      }
    }
  }
  &.yellow {
    color: #EFB851;
    border: 2px solid #EFB851;
    width: fit-content;
    &.icon-right {
      svg,
      svg path {
        @extend %transition;
        fill: #EFB851;
      }
    }
  }
  @include breakpoint(XS) {
    display: block;
    text-align: center;
  }
}

.icon-right {
  display: inline-flex;
  align-items: center;
  @extend %transition;
  width: fit-content;

  svg,
  svg path {
    @extend %transition;
    fill: var(--color-body);
  }

  &:hover {
    svg,
    svg path {
      fill: var(--color-background);
    }
  }

  &.white {
    svg,
    svg path {
      fill: var(--color-white);
    }

    &:hover {
      svg,
      svg path {
        fill: var(--color-body);
      }
    }
  }
}

input[type="submit"].button {
  &:hover {
    background: var(--color-body);
    color: var(--color-background);
  }

  &.white:hover {
    background: var(--color-background);
    color: var(--color-body);
  }
}

input[type="checkbox"] {
  width: auto;
  display: none;

  + span {
    position: relative;
    padding-left: 55px;
    cursor: pointer;
    @extend %transition;
    margin-right: 15px;
    display: block;
    font-weight: bold;
    text-transform: uppercase;

    &:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      position: absolute;
      left: 0;
      top: -0.15em;
      font: normal normal normal 14px/1 FontAwesome;
      border: 1px solid var(--color-body);
      color: #fff;
      text-align: center;
      line-height: 1em;
      font-size: 1.78em;
      @extend %transition;
      cursor: pointer;
    }
  }

  &:checked + span {
    color: var(--color-accent-primary);

    &:before {
      content: "\f00c";
      background: var(--color-accent-primary);
    }
  }
}

input[type="radio"] {
  width: auto;
  display: none;
  font-family: $font-stack-primary;

  + span {
    position: relative;
    padding-left: 2em;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      border: 1px solid #979797;
      text-align: center;
      line-height: 1.5;
      font-size: 1em;
      @extend %transition;
      cursor: pointer;
      transform: rotate(-45deg);
    }
  }

  &:checked + span:before {
    background: var(--color-accent-primary);
  }
}

.dropdown {
  overflow: hidden;
  position: relative;
  border: 2px solid var(--color-body);
  cursor: pointer;
  margin-bottom: 1em;
  width: 100%;
  display: block;
  cursor: pointer;
  color: var(--color-body);
  background: #fff;

  select {
    width: 130%;
    appearance: none;
    background: none;
    color: var(--color-accent-primary);
    border-radius: 0;
    border: 0;
    padding: 1em;
    line-height: 1;
    position: relative;
    z-index: 2;
    display: block;
    font-size: 1em;
    font-weight: 300;
    cursor: pointer;
    font-family: $font-stack-primary;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    width: 1em;
    position: absolute;
    content: "";
    right: 1em;
    top: 1.25em;
    height: 1em;
    z-index: 1;
    background-image: url("/wp-content/themes/ADAO/images/arrow-down.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.input-wrap {
  position: relative;

  .datepicker {
    border-radius: 0;

    &:after {
      position: absolute;
      width: 1.5em;
      height: 2em;
      content: "";
      right: 1.5em;
      top: 0.9em;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

div.wpcf7-response-output {
  margin: 1em 0 0 0;
  border: 0;
  clear: both;
  color: #fff;
  padding: 1em;
  background: var(--color-body);
  font-size: 1.4em;
  position: relative;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: -16px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12.5px 16px 12.5px;
    border-color: transparent transparent var(--color-body) transparent;
  }
}

.wpcf7-not-valid-tip {
  margin-top: -1em;
}

.page-title-minimal {
  border-bottom: 2px solid var(--color-body);
  margin-bottom: 2em;
  h2 {
    margin-bottom: 0;
    font-size: 3em;
  }

  h3 {
    font-weight: 300;
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}

.slick-arrow {
  width: 2.5em;
  height: 2.5em;
  border: 2px solid #fff;
  background: rgba(96, 96, 96, 0.3);
  bottom: 0;
  top: auto;
  left: auto;
  right: 0;
  opacity: 1;
  position: absolute;
  z-index: 3;
  cursor: pointer;

  img {
    width: 1.5em;
    height: 1.5em;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &.slider-prev {
    right: 6em;
    bottom: 4em;
  }

  &.slider-next {
    right: 2em;
    bottom: 4em;
  }

  &:hover {
    background: rgba(96, 96, 96, 0.5);
  }
}

.full-width-img {
  width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.slick-arrow {
  @extend %transition;
}

.date-input {
  position: relative;
  background: #fff;
  margin-bottom: 1em;

  input {
    background: transparent;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
  }

  svg {
    width: 2em;
    height: 2em;
    position: absolute;
    content: "";
    right: 1em;
    top: 0.5em;
    z-index: 1;

    path {
      stroke: var(--color-body);
      stroke-width: 25;
    }
  }
}

.accordion {
  .accordion-title {
    text-transform: none;
    border-top: 1px solid var(--color-body);
    font-size: 1.333em;
    padding-left: 4em;
    padding-top: 0.25em;
    cursor: pointer;
    position: relative;
    @extend %transition;

    &:before {
      position: absolute;
      content: "+";
      top: -1px;
      left: 0;
      border: 1px solid var(--color-body);
      text-align: center;
      width: 1em;
      height: 1em;
      font-size: 2em;
      line-height: 0.78em;
      font-style: normal;
    }

    &.open {
      &:before {
        content: "-";
      }
    }

    &:hover {
      color: var(--color-accent-primary);
    }
  }
  .accordion-content {
    padding-left: 5.25em;
  }
}

div.wpcf7 {
  max-width: 50em;
  margin: 4em auto;

  @include breakpoint(S) {
    margin: 2em auto;
  }
}

form {
  h2 {
    clear: both;
  }
  p {
    clear: both;
    margin: 0;
  }
  .half {
    width: calc(50% - 1em);
    margin: 0 1em 1em 0;

    @include breakpoint(S) {
      width: 100%;
      margin-right: 0;
    }
    &.last {
      margin-right: 0;

      @include breakpoint(S) {
        width: 100%;
      }
    }
  }
  .full-width {
    display: block;
    width: 100%;
    clear: both;
    padding: 0 1em 1em 0;
  }
}

.ginput_container_text,
.ginput_container_email,
.ginput_container_phone {
  input {
    border: none;
    border-bottom: 2px solid $color-tertiary;

    &:focus {
      border-color: var(--color-accent-primary);
    }
  }
}

.gfield_checkbox {
  div {
    input {
      margin: 0 3px 3px 4px;
      width: 1rem;
      height: 1rem;
    }
  }
}

.input-wrap {
  position: relative;

  input {
    border: 0;
    border-bottom: 2px solid $color-tertiary;
    margin-bottom: 2em;
    padding-top: 0;
    padding-left: 0.5em;

    &:focus {
      border-bottom: 2px solid var(--color-accent-primary);
    }
  }

  .label {
    position: absolute;
    line-height: 1;
    left: 0.5em;
    top: 0;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-tertiary;
    @extend %transition;
  }

  &.focus {
    .label {
      color: var(--color-accent-primary);
      top: 2.5em;
    }
  }
  &.file {
    .label {
      left: auto;
      right: 0.5em;
    }
    &.focus {
      .label {
        top: 3em;
      }
    }
  }
  &.has-content {
    input {
      border-bottom: 2px solid var(--color-body);
    }
    .label {
      top: 2.5em;
      color: var(--color-body);
    }
    &.file {
      .label {
        top: 3em;
      }
    }
  }
}

.has-content {
  textarea {
    border: 2px solid var(--color-body);
  }
}

.look {
  border: 2px solid var(--color-accent-primary);
  padding: 0.5em 0.25em;
  display: inline-flex;

  svg {
    margin: 0 0.25em;
  }
  svg,
  svg path {
    @extend %transition;
  }

  &:hover {
    svg:last-child {
      margin-left: 0.5em;
    }
  }
}

span.wpcf7-list-item {
  margin: 0;
}

.aligncenter {
  margin: 0 auto;
}

hr {
  border: 0;
  border-bottom: 2px solid #85d3cf;
  margin: 2.25em 0;
}

.post-meta {
  display: flex;
  align-items: center;
  color: #333;
  margin: 1em 0;
  justify-content: center;

  &__details {
    margin-left: 1em;
  }

  &__author {
    font-weight: bold;

    a {
      color: #333;
    }
  }

  &__date {
    color: #33333382;
    font-weight: normal;
  }

  &__avatar {
    width: 4em;
    height: 4em;

    img {
      border-radius: 50%;
    }
  }
}

.hide-mobile {
  @include breakpoint(XS) {
    display: none !important;
  }
}

.hide-tablet {
  @include breakpoint(S) {
    display: none !important;
  }
}

.show-tablet {
  display: none;

  @include breakpoint(S) {
    display: block !important;
  }
}

.hide-l {
  @include breakpoint(L) {
    display: none !important;
  }
}

.hide-desktop {
  display: none !important;

  @include breakpoint(XS) {
    display: block !important;
  }
  &.flex {
    @include breakpoint(XS) {
      display: flex !important;
    }
  }
}

.mgbutton {
  &::after {
    display: none;
  }
}
