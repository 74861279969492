//Styling for the Featured Callout section

.featured-callout {
    --section-padding: 4.5rem;
    --section-margin: 4.5rem;

    @include breakpoint(XS) {
        --section-padding: 3rem;
        --section-margin: 3rem;
    }

    background: var(--color-background);
    
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
    margin-bottom: var(--section-margin);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        z-index: 2;
    }

    &__title {
        font-weight: 700;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;

        max-width: 52rem;
    }

    &__subtitle {
        margin-top: 1rem;
        font-weight: 400;
        background: none;
        padding: 0;
        margin-left: 0;
        transform: none;
    }

    &.section-with-background {
        .image-background,
        .image-background:after,
        .image-background img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .image-background:after {
            content: "";
            background: rgba(0, 0, 0 , 0.75);
        }
    }
}