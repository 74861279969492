//Styling for the Sub-Specialism template

.page-template-page-sub-specialism {
    //Remove horizontal rule from header
    .banner {
        border-bottom: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }
}

.sub-specialism-layout {
    display: grid;
    margin-bottom: 6rem;

    @include breakpoint(S-up) {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 2rem;
    }


    .graphic-container {
        padding-top: 3rem;
        position: relative;

        @include breakpoint(S) {
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                background-color: #7ACDC8;
                width: 100vw;
                height: 100%;
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }


        @include breakpoint(S-up) {
            grid-column: 1 / span 5;
            grid-row: 1;
        }


        .graphic {
            height: 16rem;
            position: sticky;
            top: 4.5rem;

            @include breakpoint(S-up) {
                height: 28rem;
            }


            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .content-side, .title-wrapper {
        display: grid;

        @include breakpoint(S-up) {
            grid-template-columns: repeat(12, 1fr);
            grid-gap: 2rem;
            grid-column: 1 / span 12;
            grid-row: 1;
        }
    }

    .title-section, .content {
        @include breakpoint(S-up) {
            grid-column: 7 / span 5;
        }
    }

    .title-wrapper {
        @include breakpoint(S-up) {
            grid-column: 1 / span 12;
        }


        position: relative;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            background-color: #7ACDC8;
            width: 100vw;
            height: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }

    .title-section {
        padding-top: 3rem;
        padding-bottom: 3rem;
        min-height: 28rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include breakpoint(S) {
            align-items: center;
            text-align: center;
        }


        > * {
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .intro-content {
            color: #fff;
            font-weight: 700;
            font-size: 1.3125rem;
            margin-bottom: 3rem;
        }

        .button:not(.cta) {
            padding: 0;
            color: #fff;
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.125em;
            display: inline-flex;
            align-items: center;

            svg {
                margin-right: 1rem;
            }
        }

        .cta {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 700;
            border-width: 3px;
            letter-spacing: 0;
            padding: 0.75rem 1.25rem;
            display: flex;
            align-items: center;

            svg {
                margin-left: 1rem;
            }
        }
    }

    .content {
        padding-top: 3rem;

        .wp-caption-text {
            text-align: center;
            opacity: 0.5;
        }
    }
}

//Image carousel section
.sub-specialism-carousel-section {
    margin-bottom: 4.5rem;
    .sub-specialism-carousel {
        width: 100%;

        &__slide {
            min-width: 22rem;
            margin-left: 1rem;
            margin-right: 1rem;
            height: 36rem;
            position: relative;

            @include breakpoint(L) {
                height: 30rem;
            }

            @include breakpoint(M) {
                height: 24rem;
            }

            @include breakpoint(S) {
                height: 20rem;
            }

            @include breakpoint(XS) {
                height: 16rem;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .slider-controls {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;

        > * {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }

        .slick-arrow {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:after {
                display: none;
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;

            li {
                width: 1.5rem;
                height: 1.5rem;
                position: relative;
                margin-left: 0.25rem;
                margin-right: 0.25rem;
                margin-bottom: 0;

                &:before {
                    display: none;
                }
            }

            button {
                width: 100%;
                height: 100%;
                border: none;
                font-size: 0;
                line-height: 0;
                border-radius: 100%;
                background: transparent;
                border: 2px solid $color-body;
                display: block;
                padding: 0;

                &:hover:after {
                    display: none;
                }
            }

            .slick-active {
                button {
                    background: $color-accent-primary;
                }
            }
        }
    }
}

//Call to Action section above footer
.cta-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-body;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;

    &__heading {
        font-size: 3rem;
        line-height: 3.5rem;
        color: #fff;
        margin-bottom: 1.5rem;
    }

    &__link {
        font-size: 1.75rem;
        line-height: 2.25rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;

        @extend %transition;

        &:after {
            content: "";
            position: absolute;
            bottom: -0.5rem;
            height: 2px;
            background: $color-accent-primary;
            left: 0;
            right: 0;

            @extend %transition;
        }

        &:hover {
            color: mix($color-accent-primary, #fff, 25%);

            &:after {
                transform: scaleX(50%);
                background-color: mix($color-accent-primary, #fff, 25%);
            }
        }
    }
}
