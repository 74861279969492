// Font Stacks

$font-stack-primary: "Futura-web", Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
$font-stack-secondary: "lotregular", "Impact", "Haettenschweilier", "Arial Black", monospaced;


// General Breakpoints
$breakpoints: (
  "XXS": (
    max-width: 36em,
  ),
  "XXS-up": (
    min-width: calc(36em + 1px),
  ),
  "XS": (
    max-width: 40em,
  ),
  "XS-up": (
    min-width: calc(40em + 1px),
  ),
  "S": (
    max-width: 48em,
  ),
  "S-up": (
    min-width: calc(48em + 1px),
  ),
  "M": (
    max-width: 64em,
  ),
  "M-up": (
    min-width: calc(64em + 1px),
  ),
  "L": (
    max-width: 84.375em,
  ),
  "L-up": (
    min-width: calc(84.375em + 1px),
  ),
  "XL": (
    min-width: 84.375em,
  ),
  "XXL": (
    min-width: 105em,
  ),
  "XXXL": (
    min-width: 112em,
  ),
);

@import "colors/color-scss-defs";