//Load desired webfonts

@font-face {
  font-family: 'Futura-web';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/wp-content/themes/ADAO/fonts/31D5E1_1_0.woff2') format('woff2');
}

@font-face {
  font-family: 'Futura-web';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/wp-content/themes/ADAO/fonts/31D5E1_0_0.woff2') format('woff2');
}

@font-face {
  font-family: 'lotregular';
  font-display: swap;
  src: url('/wp-content/themes/ADAO/fonts/lot-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}


//Futura Regular Fallbacks

@font-face {
  font-family: 'Avenir';
  src: local('Avenir');
  size-adjust: 97%;
  ascent-override: 106%;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat');
  size-adjust: 85.125%;
  ascent-override: 100%;
}

@font-face {
  font-family: 'Corbel';
  src: local('Corbel');
  size-adjust: 105%;
  ascent-override: 89%;
}

@font-face {
  font-family: 'URW Gothic';
  src: local('URW Gothic');
  size-adjust: 90%;
  ascent-override: 97%;
}

@font-face {
  font-family: 'source-sans-pro';
  src: local('Source Sans Pro');
  size-adjust: 104%;
  ascent-override: 90%;
}



html {
  font-size: 100%;

  @include breakpoint(S) {
    font-size: 81.3%;
  }
  @include breakpoint(M) {
    font-size: 87.5%;
  }
  @include breakpoint(XL) {
    font-size: 106.25%;
  }
  @include breakpoint(XXL) {
    font-size: 112.5%;
  }
  @include breakpoint(XXXL) {
    font-size: 125%;
  }

}

body {
  font-family: $font-stack-primary;
  line-height: 1.5;
  font-weight: normal;
  color: var(--color-body);
}

.lot {
  font-family: $font-stack-secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-spacing: 0.125em;
  font-weight: normal;

  span {
    background: #FAF9FC;
    padding: 0.25em;
    font-size: 0.5em;
    margin-left: 0.5em;
    display: inline-block;
    transform: translateY(-0.25em);
  }
}

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1.5rem;

  @include breakpoint(S) {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

h2, .h2 {
  font-size: 2.25rem;
  line-height: 1.333;
  margin-bottom: 1.5rem;

  @include breakpoint(S) {
    font-size: 2rem;
    line-height: 1.25;
  }
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @include breakpoint(S) {
    font-size: 1.5rem;
    line-height: 1.3333;
  }
}

h4, .h4 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  letter-spacing: 1.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h5, .h5 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h6, .h6 {
  font-size: 0.875em;
  line-height: 1.715;
  margin-bottom: 0.5rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.content-container a {
  color: $color-body;
  position: relative;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--color-body);
    transition: all .3s cubic-bezier(0, 0, 0.04, 1.07);
    content: "";
  }

  &:after {
    position: absolute;
    top: -2px;
    left: 0;
    width: 0;
    bottom: 0;
    background: var(--color-body);
    content: "";
    transition: all .3s cubic-bezier(0, 0, 0.04, 1.07);
    z-index: -1;
  }

  &:hover {
    border: 0;
    color: #908f8f;
    &:before {
      width: 0;
    }
    &:after {
      width: 100%;
    }
  }
}

li {
  font-size: 1.1em;
  font-size: 1rem;
  line-height: 1.333;
  margin-bottom: 1em;
  padding-left: 0;
  position: relative;
}

ul {
  margin-bottom: 2em;

  li {

    &:before {
      width: 1em;
      height: 0.5rem;
      position: absolute;
      left: -1.25em;
      background: url('/wp-content/themes/ADAO/images/arrow.svg');
      background-repeat: no-repeat;
      background-position: left center;
      display: block;
      content: "";
      top: 0.25rem;
    }
  }
}

ol {
  margin-bottom: 2em;
  counter-reset: ol-counter;

  > li {
    padding-left: 0;

    &:before {
      position: absolute;
      right: calc(100% + 1em);
      color: var(--color-accent-primary);
      font-weight: bold;
      display: block;
    	content: counter(ol-counter);
    	counter-increment: ol-counter;
      top: 0;
      line-height: 1;
    }
  }
  ul {
    padding-left: 2em;
    padding-top: 1em;
  }
}

.container,
p {
  a {
    color: var(--color-accent-primary);
    font-weight: 700;
    position: relative;

    &:hover {
      color: $color-tertiary;
    }

    &.underlined {
      display: inline-block;
      line-height: 1.2;
      border-bottom: 2px solid var(--color-accent-primary);
      letter-spacing: 0.1em;
      margin: 0.5em;
      text-transform: uppercase;

      @include breakpoint(XS) {
        padding-bottom: 0.5em;
        display: block;
      }

      &:after {
        position: absolute;
        content: "";
        height: 2px;
        background: $color-tertiary;
        bottom: -2px;
        left: 0;
        width: 0%;
        @extend %transition;
      }

      &:hover {
      color: $color-tertiary;

        &::after {
          width: 100%;
        }
      }
    }
    &.underline {
      border-bottom: 2px solid var(--color-body);
      
      @include breakpoint(XS) {
        display: block;
      }
      &:hover {
        border-bottom: 2px solid $color-tertiary;
      }
    }
  }
}

table {
  width: 100%;
  margin-bottom: 2em;
  text-align: center;
  font-size: 1em;
  border-bottom: 1px solid var(--color-body);

  th {
    color: #fff;
    background: var(--color-accent-primary);
    padding: 1em;
    text-transform: uppercase;
    font-weight: bold;
  }
  tr {
    &:nth-child(even) {
      background: #F9F9FB;
    }
    td {
      padding: 0.7em 1em;
    }
  }
  tfoot {
    td {
      text-transform: uppercase;
      font-weight: bold;
      color: $color-accent-primary;
    }
  }
  @include breakpoint(XS) {
    overflow-x: scroll;
    display: block;
  }
}

pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  white-space: pre-wrap;
	white-space: -moz-pre-wrap !important;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
  margin-bottom: 2em;
  padding: 1em;
  background: #F9F9FB;
  border: 1px solid var(--color-body);
}
