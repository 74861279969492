//Styling for the sector list items block (found on the Sectors Page template)

.sector-list {
    --section-spacing: 4.5rem;

    @include breakpoint(XS) {
        --section-spacing: 3rem;
    }

    color: unset;

    display: grid;
    column-gap: 3rem;
    row-gap: var(--section-spacing);

    margin-bottom: var(--section-spacing);

    @include breakpoint(M-up) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 6rem;
    }
}

.sector-list-item {
    display: grid;
    row-gap: 3rem;

    @include breakpoint(S-up) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 4rem;

        &:nth-child(even) {

            .sector-list-item__content {
                align-self: flex-end;
            }

            .sector-list-item__image {
                order: 2;
            }
        }
    }

    @include breakpoint(M-up) {
        column-gap: 2rem;

        &:nth-child(even) {
            margin-top: 10.5rem;
        }
    }

    &:nth-child(even) {
        .sector-list-item__image:before {
            transform: translate(2rem, -2rem);

            @include breakpoint(XS) {
                transform: translate(1rem, -1rem);
            }
        }

        &:hover .sector-list-item__image {
            transform: translateY(1rem);
        }

        &:hover .sector-list-item__image:before {
            transform: translate(1rem, -1rem);
        }
    }

    .content {
        // font-size: 0.875rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
    }

    p, svg {
        color: $color-body;
        transition: all 0.3s cubic-bezier(.65,.05,.36,1);
    }

    .button.minor {
        color: $color-body;
        transition: all 0.3s cubic-bezier(.65,.05,.36,1);
        width: fit-content;
    }

    &:hover {
        .sector-list-item__title, svg, .button.minor {
            color: var(--highlight-color, $color-accent-primary);
        }

        //Reset the default minor button hover background shape
        .button.minor:after {
            display: none;
        }

        p {
            color: $color-body;
        }

        svg {
            transform: translateX(0.125rem);
        }

        .sector-list-item__image {
            transform: translateY(-1rem);
            filter: brightness(1.075);

            &:before {
                transform: translate(-1rem, 1rem);
            }
        }
    }
}

.sector-list-item__image {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 120%;
    transition: all 0.6s cubic-bezier(.65,.05,.36,1) 0.15s;
    justify-self: center;

    @supports (aspect-ratio: 1/1) {
        height: auto;
        aspect-ratio: 5/6;
        padding-bottom: unset;
    }

    @include breakpoint(XS) {
        width: 80%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:before {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(-2rem, 2rem);
        transition: all 0.6s cubic-bezier(.65,.05,.36,1);
        background: var(--highlight-color, $color-accent-primary);

        @include breakpoint(XS) {
            transform: translate(-1rem, 1rem);
        }
    }
}

.sector-list-item__title {
    color: $color-body;
    font-weight: 700;
    margin-bottom: 1rem;
    transition: all 0.3s cubic-bezier(.65,.05,.36,1);
    will-change: color;

    * {
        transition: all 0.3s cubic-bezier(.65,.05,.36,1);
    }
}

.sector-list-item__title-icon {
    display: inline;
    white-space: nowrap;
}